import React, { useEffect, useState } from "react";
import "./newAssessmentForm.scss";
import Question from "./questions/Question";
import History from "./history/History";
import Findings from "./findings/Findings";
import FileUpload from "./attachment/Attachment";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { useNavigate, useParams } from "react-router-dom";
import { convertToIso, db } from "../../../../database";
import { updateNewAssessmentVariable } from "../../../../redux/slices/globalVariableSlice";
import { toast } from "react-toastify";
import ProjectDetailsBar from "../project-details-bar/ProjectDetailsBar";
import axiosInstance from "../../../../httpInterceptor";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import Modal from "react-modal";
import {
  AssessmentData,
  ProjectDataType,
  Qnadetails,
} from "../../../../models/interfaces/interfaces";
import Loading from "../../../layout/loader/Loading";
import axios from "axios";
import { transform } from "typescript";

const NewAssessmentForm: React.FC = () => {
  const { proj_id, set_name } = useParams<{
    proj_id: string;
    set_name: string;
  }>();

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  let newAssessmentVar = JSON.parse(
    sessionStorage.getItem("newAssessment") || ""
  );
  // state for changing the tabs between question,history and findings
  const [activeTab, setActiveTab] = useState("questions");
  const [isReAssessClicked, setIsReAssessClicked] = useState(false);
  const [reviewer, setReviewerData] = useState<any>(null);
  const [reviewerNames, setReviewerNames] = useState<any>(null);
  const [isAssessForThisProjId, setIsAssessForThisProjId] =
    useState<boolean>(true);
  //state to handle accordion like to show or hide the project form
  const [isopen, setIsOpen] = useState(false);
  const [projectHealthData, setProjectHealthData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [isEnabled, setIsEnabled] = useState(false);

  const [setname, setName] = useState("");
  const [emails, setEmails] = useState<string[]>([]);
  const [showEmailField, setShowEmailField] = useState(false);
  const [isSubmitButton, setIsSubmitButton] = useState(false);
  const [color, setColor] = useState<string[]>([]);

  useEffect(() => {
    newAssessmentVar = JSON.parse(
      sessionStorage.getItem("newAssessment") || ""
    );
  }, [isReAssessClicked]);

  const updateQnaDetails = (response: Qnadetails, len: number) => {
    if (Object.keys(response).length === len) {
      toast.success("data saved");
    }
    setAssessmentData((prevState: any) => {
      const updatedDetails = prevState.assessmentdetails.map((detail: any) => ({
        ...detail,
        qnadetails: response,
      }));
      return { ...prevState, assessmentdetails: updatedDetails };
    });
  };

  //state for setting the new Assessment Data
  const [projectData, setProjectData] = useState<ProjectDataType>({
    proj_id: "",
    proj_name: "",
    type: "",
    pmid: "",
    pmname: "",
    du: "",
    pu: "",
    account: "",
    billing_project_id: "",
    proj_start_date: "",
    proj_end_date: "",
    proj_desc: "",
    reviewer: "",
    status: "",
    set_name: "",
  });

  const [assessmentData, setAssessmentData] = useState<AssessmentData>({
    projectid: proj_id || "",
    set_name: set_name || "",
    emailIds: JSON.parse(localStorage.getItem("emailIds") || "[]"),
    assessmentdetails: [
      {
        assessmentdate: `${new Date().toISOString().split("T")[0]}`,
        projectstatus: "",
        reasonforchange: "",
        qnadetails: [],
        created_by: `${sessionStorage.getItem("PBT_FIRSTNAME") || ""} ${
          sessionStorage.getItem("PBT_LASTNAME") || ""
        }`,
        modified_by: "",
      },
    ],
  });

  const fetchUserDataForReviewer = async (query: string) => {
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_USER_SERVICE_BASE_URL}/userdata`
      );

      const result = res.data.filter((item: any) => {
        if (!query) {
          setReviewerData(null);
        }
        if (item.role === "Reviewer" || item.role === "Admin") {
          const searchText = query.toLowerCase();
          const fullName = item.name.toLowerCase();

          return query && fullName.startsWith(searchText);
        }
      });
      setReviewerData(result);
      const names = result.map((item: any) => {
        const { name, ...rest } = item;
        return name;
      });
      setReviewerNames(names);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setProjectData({ ...projectData, [name]: value });
    if (name === "reviewer") fetchUserDataForReviewer(value);
    console.log(e);
    if (e.target.name === "proj_desc" || e.target.name === "reviewer") {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  };

  const handleAssesmentChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setAssessmentData((prevState) => ({
      ...prevState,
      assessmentdetails: prevState.assessmentdetails.map((detail, i) => ({
        ...detail,
        [name]: value,
      })),
    }));
  };

  const exsistingFunctionWithColorChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    handleAssesmentChange(e);
    const newColor = e.target.value;
    setColor((prevColors) => [...prevColors, newColor]);
    localStorage.setItem("colors", JSON.stringify(color));
  };
  useEffect(() => {
    localStorage.setItem("colors", JSON.stringify(color));
  }, [color]);

  useEffect(() => {
    const fetProjectData = async () => {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_DASHBOARD_SERVICE_BASE_URL}/projects`
      );

      const localData = res.data.filter(
        (item: { projectid: string; set_name: string }) =>
          item.projectid === proj_id && item.set_name === set_name
      );

      if (localData.length > 0) {
        setName(localData[0].set_name);
      } else {
        console.log("No data found for the given project ID and set.");
      }
    };
    fetProjectData();
  }, [proj_id, set_name]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axiosInstance.get(
          `${process.env.REACT_APP_COMPASS_BOOMI_URL}?Projectid=${proj_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": `${process.env.REACT_APP_COMPASS_X_API_KEY}`,
            },
          }
        );
        //data coming from compass2.0 api
        const compassData = data.result[0];
        //data coming from local db
        const res = await axiosInstance.get(
          `${process.env.REACT_APP_DASHBOARD_SERVICE_BASE_URL}/projects`
        );
        const localData = res.data.filter(
          (item: { projectid: string; set_name: string }) =>
            item.projectid === proj_id && item.set_name === set_name
        );
        if (localData.length > 0) {
          const {
            projectNumber,
            projectName,
            u_project_category,
            primaryPM_psno,
            primaryPM,
            u_delivery_unit,
            u_practice_unit,
            accountName,
            billing_project_id,
            start_date,
            end_date,
          } = compassData;

          const { proj_desc, reviewer, status, set_name } = localData[0];

          setProjectData({
            proj_id: projectNumber,
            proj_name: projectName,
            type: u_project_category,
            pmid: primaryPM_psno,
            pmname: primaryPM,
            du: u_delivery_unit,
            pu: u_practice_unit,
            account: accountName,
            billing_project_id: billing_project_id,
            proj_start_date: start_date,
            proj_end_date: end_date,
            proj_desc,
            reviewer,
            status,
            set_name,
          });
        } else {
          console.log("No data found for the given project ID and set.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();

    const fetchProjectHealthData = async () => {
      try {
        const { data } = await axiosInstance.get(
          `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/get-projectassesment/${proj_id}/${set_name}`
        );
        console.log("data", data);
        if (data.length === 0) {
          dispatch(updateNewAssessmentVariable(true));
          setIsAssessForThisProjId(false);
        }
        const len = data[0].assessmentdetails.length;
        const qnadetailsWithQuestions = await Promise.all(
          data[0].assessmentdetails[len - 1].qnadetails.map(
            async (item: {
              questionid: string;
              response: string;
              remarks: string;
            }) => {
              const question = await axiosInstance.get(
                `${process.env.REACT_APP_QUESTION_SERVICE_BASE_URL}/questions/${item.questionid}`
              );
              return {
                questionid: item.questionid,
                description: question.data.description,
                type: question.data.type,
                response: item.response,
                remarks: item.remarks,
              };
            }
          )
        );
        console.log("qnas", qnadetailsWithQuestions);
        const {
          assessmentdate,
          projectstatus,
          reasonforchange,
          created_by,
          modified_by,
          created_date,
          modified_date,
        } = data[0].assessmentdetails[len - 1];
        const emailIds = data[0].emailIds;
        const latestAssessment = {
          proj_id,
          set_name: set_name,
          emailIds: emailIds,
          assessmentdetails: [
            {
              assessmentdate,
              projectstatus,
              reasonforchange,
              created_by,
              created_date,
              modified_by,
              modified_date,
              qnadetails: qnadetailsWithQuestions,
            },
          ],
        };
        console.log("assesment", latestAssessment);
        setProjectHealthData([latestAssessment]);
      } catch (error) {
        console.error("Error fetching the data", error);
      } finally {
        setLoading(false);
      }
    };
    console.log("health", projectHealthData);
    if (newAssessmentVar === false) {
      fetchProjectHealthData();
    }
  }, [proj_id, set_name]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axiosInstance.get(
          `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/get-projectassesment/${proj_id}/${set_name}`
        );
        console.log(res);
        console.log("assess", assessmentData);
        console.log("emails", res.data[0].emailIds);
        setEmails(res.data[0].emailIds || []);
        console.log(emails);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [proj_id, set_name]);

  const handleButtonClick = async () => {
    setShowEmailField(true);
  };
  const handleCloseModal = () => {
    setShowEmailField(false);
  };
  const handleSaveEmails = async () => {
    const projectKey = `${assessmentData.projectid}_${assessmentData.set_name}`;
    const storedData = JSON.parse(localStorage.getItem("emailData") || "{}");
    const existingEmailIds = storedData[projectKey] || [];
    // Merge and deduplicate email IDs
    const emailSet = new Set([...existingEmailIds, ...emails]);
    const updatedEmailIds = Array.from(emailSet);
    // Store the updated email IDs in localStorage
    storedData[projectKey] = updatedEmailIds;
    localStorage.setItem("emailData", JSON.stringify(storedData));
    alert("Email IDs saved successfully!");
    setEmails(updatedEmailIds);
    setShowEmailField(false);
    // Send the combined email IDs to the backend
    if (assessmentData.projectid && assessmentData.set_name) {
      try {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/create-projectassesment`,
          {
            projectid: assessmentData.projectid,
            set_name: assessmentData.set_name,
            emailIds: updatedEmailIds,
          }
        );
        console.log("Response from API:", response);
        if (response.data) {
          toast.success("Email IDs updated successfully");
        }
      } catch (error) {
        console.error("Error updating email IDs:", error);
        toast.error("Failed to update email IDs");
      }
    }
  };
  const handleNewAssessmentFormSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    const isResponseSaved = assessmentData.assessmentdetails.map((detail) => {
      return detail.qnadetails.length > 0;
    });

    if (isResponseSaved.every((saved) => saved)) {
      try {
        const storedData = JSON.parse(
          localStorage.getItem("emailData") || "{}"
        );
        const projectKey = `${assessmentData.projectid}_${assessmentData.set_name}`;
        const updatedAssessmentData = {
          ...assessmentData,
          emailIds: storedData[projectKey] || [],
        };
        if (updatedAssessmentData.emailIds.length === 0) {
          toast.error("Please enter valid email IDs.");
          return;
        }
        // Send assessment details via email
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/create-projectassesment`,
          updatedAssessmentData
        );
        if (response.data) {
          toast.success("Assessment Completed successfully");
          setIsEnabled(false);
          setIsSubmitButton(true);
        }
        const emailServiceBaseUrl =
          process.env.REACT_APP_EMAIL_SERVICE_BASE_URL;
        const endpoint = `${emailServiceBaseUrl}`;

        console.log(`Sending email to: ${endpoint}`);
        const emailResponse = await axiosInstance.post(
          `${process.env.REACT_APP_EMAIL_SERVICE_BASE_URL}/email`,
          {
            projectid: assessmentData.projectid,
            setName: assessmentData.set_name,
            toEmail: response.data.emailIds,
            emailType: "assessment",
          }
        );
        if(emailResponse.data){
          toast.success("Email send successfully, Thank you");
        }
        console.log("Email Response:", emailResponse);
        setTimeout(() => {
          navigate("/dashboard");
        }, 2400);
      } catch (error) {
        console.log("Error:", error);
        toast.error("Failed to complete assessment");
        setIsSubmitButton(true);
      } finally {
        setLoading(false);
      }
    } else {
      console.log("Unsaved changes!");
      toast.error("You have some unsaved changes, please save to continue");
      setIsSubmitButton(true);
    }
  };

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);

    if (tabName === "questions") {
      setAssessmentData((prevState) => ({
        ...prevState,
        assessmentdetails: prevState.assessmentdetails.map((detail) => ({
          ...detail,
          projectstatus: "",
          reasonforchange: "",
          assessmentdate: `${new Date().toISOString().split("T")[0]}`,
        })),
      }));
    }
  };

  const handleOpenClose = () => {
    setIsOpen(!isopen);
  };
  const handleEditSubmit = async () => {
    const { proj_desc, reviewer } = projectData;
    if (!reviewerNames.includes(projectData.reviewer)) {
      toast.error("Please select reviewer from suggestions!");
      return;
    }
    try {
      const res = await axiosInstance.patch(
        `${process.env.REACT_APP_DASHBOARD_SERVICE_BASE_URL}/update/${proj_id}`,
        { proj_desc, reviewer }
      );
      if (isEnabled) {
        toast.success("Project Data is updated successfully");
        setIsEnabled(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleReviewerOnClick = (value: string) => {
    setProjectData((prevData: any) => ({
      ...prevData,
      reviewer: value,
    }));
    setReviewerData(null);
  };

  const handleReviewerBlur = () => {
    setTimeout(() => {
      setReviewerData(null);
    }, 150);
  };
  const handleRemoveEmail = async (index: number) => {
    const emailToRemove = emails[index];
    try {
      const response = await axiosInstance.delete(
        `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/deleteEmail/${proj_id}/${set_name}`,
        { data: { emailId: emailToRemove } }
      );
      if (response.data.message) {
        toast.success(response.data.message);
        setEmails(emails.filter((_, i) => i !== index));
        localStorage.removeItem("emailData");
      }
    } catch (error) {
      console.error("Error deleting email:", error);
      toast.error("Failed to delete email");
    }
  };

  const showDetails =
    projectData.proj_id !== "" && projectData.proj_name !== "";

  return (
    <>
      <div>
        <button className="notify" onClick={handleButtonClick}>
          Set Notification
        </button>

        <Modal
          isOpen={showEmailField}
          onRequestClose={handleCloseModal}
          contentLabel="Email"
          className="modal_box"
        >
          <div className="email-field">
            <label htmlFor="email" className="notifyEmail">
              Enter Email Id :
            </label>
            <ReactMultiEmail
              className="custom"
              placeholder="Email Address"
              emails={emails}
              onChange={(_emails) => {
                setEmails(_emails);
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                    <span
                      data-tag-handle
                      onClick={() => handleRemoveEmail(index)}
                    >
                      ×
                    </span>
                  </div>
                );
              }}
            />
            <div className="button-email">
              <button className="save-email" onClick={handleSaveEmails}>
                Save
              </button>
              <button className="cross" onClick={handleCloseModal}>
                Close
              </button>
            </div>
          </div>
        </Modal>
      </div>

      <form onSubmit={handleNewAssessmentFormSubmit}>
        <ProjectDetailsBar
          projectid={projectData.proj_id}
          projectName={projectData.proj_name}
          showDetails={showDetails}
          handleProjectDetailsBar={handleOpenClose}
        />

        {isopen && (
          <div className="newAssessmentForm">
            <div className="newAssessmentFormRow">
              <div className="row">
                <label>Project Id</label>
                <input
                  type="text"
                  name="proj_id"
                  autoComplete="off"
                  readOnly
                  required
                  disabled
                  value={projectData.proj_id}
                  onChange={handleChange}
                />
              </div>

              <div className="row">
                <label>Project Name</label>
                <input
                  type="text"
                  name="proj_name"
                  autoComplete="off"
                  required
                  readOnly
                  disabled
                  value={projectData.proj_name}
                  onChange={handleChange}
                />
              </div>
              <div className="row">
                <label>Type</label>
                <input
                  type="text"
                  name="type"
                  autoComplete="off"
                  required
                  readOnly
                  disabled
                  value={projectData.type}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="newAssessmentFormRow">
              <div className="row">
                <label>PMID</label>
                <input
                  type="text"
                  autoComplete="off"
                  name="pmid"
                  required
                  readOnly
                  disabled
                  value={projectData.pmid}
                  onChange={handleChange}
                />
              </div>
              <div className="row">
                <label>PMNAME</label>
                <input
                  type="text"
                  autoComplete="off"
                  name="pmname"
                  required
                  readOnly
                  disabled
                  value={projectData.pmname}
                  onChange={handleChange}
                />
              </div>
              <div className="row">
                <label>Account</label>
                <input
                  type="text"
                  name="account"
                  autoComplete="off"
                  required
                  readOnly
                  disabled
                  value={projectData.account}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="newAssessmentFormRow">
              <div className="row">
                <label>DU</label>
                <input
                  type="text"
                  name="du"
                  autoComplete="off"
                  required
                  readOnly
                  disabled
                  value={projectData.du}
                  onChange={handleChange}
                />
              </div>
              <div className="row">
                <label>PU</label>
                <input
                  type="text"
                  name="pu"
                  autoComplete="off"
                  required
                  readOnly
                  disabled
                  value={projectData.pu}
                  onChange={handleChange}
                />
              </div>
              <div className="row">
                <label>Project Start Date</label>
                <input
                  type="text"
                  name="proj_start_date"
                  autoComplete="off"
                  required
                  readOnly
                  disabled
                  value={convertToIso(projectData.proj_start_date)}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="newAssessmentFormRow">
              <div className="row">
                <label>Project End Date</label>
                <input
                  type="text"
                  value={convertToIso(projectData.proj_end_date)}
                  name="proj_end_date"
                  readOnly
                  disabled
                  onChange={handleChange}
                />
              </div>
              <div className="row">
                <label>Project Description</label>
                <textarea
                  name="proj_desc"
                  value={projectData.proj_desc}
                  autoComplete="off"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="row projectIdInputBoxWithSuggestions">
                <label>Reviewer</label>
                <input
                  type="text"
                  autoComplete="off"
                  name="reviewer"
                  onFocus={(e) => fetchUserDataForReviewer(e.target.value)}
                  onBlur={handleReviewerBlur}
                  value={projectData.reviewer}
                  onChange={handleChange}
                />
                <ul
                  className={reviewer?.length > 0 ? "showReviewerResults" : ""}
                >
                  {reviewer?.length > 0 &&
                    reviewer.map((result: any, idx: number) => {
                      return (
                        <li
                          className="resultItems"
                          key={idx}
                          onClick={() => handleReviewerOnClick(result.name)}
                        >
                          <span> {result.name}</span>
                        </li>
                      );
                    })}
                </ul>
              </div>
              <div className="row">
                <label>Question SET</label>
                <input
                  type="text"
                  name="set_name"
                  autoComplete="off"
                  required
                  readOnly
                  disabled
                  value={projectData.set_name}
                  onChange={handleChange}
                />
              </div>
              <div className="row">
                <label>Billing Project Id</label>
                <input
                  type="text"
                  name="billing_project_id"
                  autoComplete="off"
                  required
                  readOnly
                  disabled
                  value={projectData.billing_project_id}
                  onChange={handleChange}
                />
              </div>
            </div>
            <section className="archiveProject">
              <div>
                <input
                  className="archiveCheckbox"
                  type="checkbox"
                  name="status"
                  value="closed"
                  checked={projectData.status === "closed" ? true : false}
                  onChange={handleChange}
                  id="status"
                />
                <label htmlFor="status">Archive</label>
                <div className="handleEditButton">
                  {isEnabled && (
                    <h2 className="buttonEdit" onClick={handleEditSubmit}>
                      Submit
                    </h2>
                  )}
                </div>
              </div>
            </section>
          </div>
        )}
        

        {/* section for questions history and findings */}
        <section className="newAssessmentTabSection">
          {newAssessmentVar === "true" ? (
            <div className="tabsContainer">
              <p
                style={
                  activeTab === "questions"
                    ? {
                        backgroundColor: "#074173",
                        color: "white",
                        borderTopLeftRadius: "10px",
                      }
                    : { color: "tomato" }
                }
                onClick={() => handleTabClick("questions")}
              >
                Questions
              </p>
              <p
                style={
                  activeTab === "findings"
                    ? { backgroundColor: "#074173", color: "white" }
                    : { color: "tomato" }
                }
                onClick={() => handleTabClick("findings")}
              >
                Findings
              </p>
              <p
                style={
                  activeTab === "attachment"
                    ? { backgroundColor: "#074173", color: "white" }
                    : { color: "tomato" }
                }
                onClick={() => handleTabClick("attachment")}
              >
                FileUpload
              </p>
            </div>
          ) : (
            <div className="tabsContainer">
              <p
                style={
                  activeTab === "questions"
                    ? {
                        backgroundColor: "#074173",
                        color: "white",
                        borderTopLeftRadius: "10px",
                      }
                    : { color: "tomato" }
                }
                onClick={() => handleTabClick("questions")}
              >
                Questions
              </p>
              <p
                style={
                  activeTab === "history"
                    ? { backgroundColor: "#074173", color: "white" }
                    : { color: "tomato" }
                }
                onClick={() => handleTabClick("history")}
              >
                History
              </p>
              <p
                style={
                  activeTab === "findings"
                    ? { backgroundColor: "#074173", color: "white" }
                    : { color: "tomato" }
                }
                onClick={() => handleTabClick("findings")}
              >
                Findings
              </p>
              <p
                style={
                  activeTab === "attachment"
                    ? { backgroundColor: "#074173", color: "white" }
                    : { color: "tomato" }
                }
                onClick={() => handleTabClick("attachment")}
              >
                FileUpload
              </p>

              {isAssessForThisProjId && activeTab === "questions" && (
                <div className="reAssessBtn">
                  {newAssessmentVar === false ? (
                    <input
                      type="button"
                      onClick={() => {
                        setIsReAssessClicked(true);
                        setIsSubmitButton(false);
                        dispatch(updateNewAssessmentVariable(true));
                      }}
                      value={"Re-Assess"}
                    />
                  ) : (
                    <input
                      type="button"
                      onClick={() => {
                        setIsReAssessClicked(false);
                        setIsSubmitButton(true);
                        dispatch(updateNewAssessmentVariable(false));
                      }}
                      value={"Cancel"}
                    />
                  )}
                </div>
              )}
            </div>
          )}
          <div>
            {newAssessmentVar === "true" ? (
              projectData.set_name !== "" ? (
                (activeTab === "questions" && (
                  <Question
                    projectid={proj_id}
                    setname={set_name}
                    onData={updateQnaDetails}
                  />
                )) ||
                (activeTab === "findings" && <Findings />)
              ) : (
                <Loading />
              )
            ) : projectData.set_name !== "" ? (
              (activeTab === "questions" && (
                <Question
                  projectid={proj_id}
                  setname={set_name}
                  onData={updateQnaDetails}
                />
              )) ||
              (activeTab === "history" && (
                <History projectid={proj_id} set={set_name} color={color} />
              )) ||
              (activeTab === "findings" && <Findings />) ||
              (activeTab === "attachment" && (
                <FileUpload
                  projectid={proj_id}
                  setName={set_name}
                  emails={emails}
                />
              ))
            ) : (
              <Loading />
            )}
          </div>
        </section>
        {/* section for the project health */}
        {activeTab === "questions" && (
          <section className="projectHealthSectionMain">
            <div>
              <div className="projectHealthSection">
                <p>Project Health</p>
                <div className="projectHealthInputContainer">
                  {newAssessmentVar === false &&
                  projectHealthData.length > 0 ? (
                    <div>
                      <label>Project Status</label>
                      <select
                        name="projectstatus"
                        onChange={exsistingFunctionWithColorChange}
                        disabled={projectHealthData.length > 0}
                        value={
                          projectHealthData[0].assessmentdetails[0]
                            .projectstatus
                        }
                      >
                        <option defaultValue="Select Project Status">
                          Select Project Status
                        </option>
                        <option value="red">Red</option>
                        <option value="amber">Amber</option>
                        <option value="green">Green</option>
                      </select>
                    </div>
                  ) : (
                    <div>
                      <label>Project Status</label>
                      <select
                        name="projectstatus"
                        onChange={exsistingFunctionWithColorChange}
                        value={
                          assessmentData.assessmentdetails[0].projectstatus
                        }
                      >
                        <option defaultValue="Select Project Status">
                          Select Project Status
                        </option>
                        <option value="red">Red</option>
                        <option value="amber">Amber</option>
                        <option value="green">Green</option>
                      </select>
                    </div>
                  )}
                  {newAssessmentVar === false &&
                  projectHealthData.length > 0 ? (
                    <div>
                      <label>Reason for change</label>
                      <input
                        type="text"
                        name="reasonforchange"
                        onChange={exsistingFunctionWithColorChange}
                        readOnly
                        value={
                          projectHealthData[0].assessmentdetails[0]
                            .reasonforchange
                        }
                      />
                    </div>
                  ) : (
                    <div>
                      <label>Reason for change</label>
                      <input
                        type="text"
                        name="reasonforchange"
                        onChange={handleAssesmentChange}
                        value={
                          assessmentData.assessmentdetails[0].reasonforchange
                        }
                      />
                    </div>
                  )}
                  {newAssessmentVar === false &&
                  projectHealthData.length > 0 ? (
                    <div>
                      <label>Date</label>
                      <input
                        type="date"
                        name="assessmentdate"
                        readOnly
                        value={
                          projectHealthData[0].assessmentdetails[0].assessmentdate.split(
                            "T"
                          )[0]
                        }
                        onChange={handleAssesmentChange}
                      />
                    </div>
                  ) : (
                    <div>
                      <label>Date</label>
                      <input
                        type="date"
                        name="assessmentdate"
                        readOnly
                        value={
                          assessmentData.assessmentdetails[0].assessmentdate
                        }
                        onChange={handleAssesmentChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="newAssessmentFormBtn">
              <button
                disabled={!newAssessmentVar || isSubmitButton}
                type="submit"
                style={{ backgroundColor: " #ff9800" }}
              >
                Submit
              </button>
            </div>
          </section>
        )}
      </form>
    </>
  );
};

export default NewAssessmentForm;
