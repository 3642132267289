import React, { useEffect, useState, useRef, MouseEvent } from "react";
import Navbar from "../../layout/header/Header";
import "./leadershipReport.scss";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import axiosInstance from "../../../httpInterceptor";
import {
  AssessmentData,
  ProjectDataType,
} from "../../../models/interfaces/interfaces";
import * as XLSX from "xlsx";
import { exportToPDF } from "./pdfExport";
import saveas from "file-saver";

import { Button, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

Chart.register(CategoryScale);
Chart.register(ChartDataLabels);

const LeadershipReport = () => {
  const [selectedDUValue, setSelectedDUValue] = useState("All");
  const [selectedPUValue, setSelectPUValue] = useState("All");
  const [selectedAccountValue, setSelectAccountValue] = useState("All");
  const [flag, setFlag] = useState(false);
  const [duOptions, setDUoptions] = useState<string[]>([]);
  const [puOptions, setPUoptions] = useState<string[]>([]);
  const [accountOptions, setAccountoptions] = useState<string[]>([]);
  const [originalPUOptions, setOriginalPUOptions] = useState<
    { du: string; pu: string }[]
  >([]);
  const [originalAccountOptions, setOriginalAccountOptions] = useState<
    { du: string; account: string }[]
  >([]);
  const [filterFlag, setFilterType] = useState<string>("All");
  const [projectListData, setProjectListData] = useState<ProjectDataType[]>([]);
  const [selectedValue, setSelectedValue] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [arrayAmber, setArrayAmber] = useState<number[]>([]);
  const [arrayRed, setArrayRed] = useState<number[]>([]);
  const [arrayGreen, setArrayGreen] = useState<number[]>([]);
  const [observationsArray, setObservationArray] = useState<number[]>([]);
  const [openStatusDataset, setOpenStatusArray] = useState<number[]>([]);
  const [inprogressDataset, setInprogressArray] = useState<number[]>([]);
  const [closedStatusDataset, setClosedStatusArray] = useState<number[]>([]);
  const [completedProjectCount, setCompletedProject] = useState<number[]>([]);
  const [yearArray, setYears] = useState<number[]>([]);
  const [isDisabled, setDisabled] = useState(false);
  const [observationData, setObservationData] = useState<any[]>([]);
  let PBTAssessmentData: any[] = [];

  const [quarter1AmberCount, setquarter1AmberCount] = useState(0);
  const [quarter1RedCount, setquarter1RedCount] = useState(0);
  const [quarter1GreenCount, setquarter1GreenCount] = useState(0);

  const [quarter2AmberCount, setquarter2AmberCount] = useState(0);
  const [quarter2RedCount, setquarter2RedCount] = useState(0);
  const [quarter2GreenCount, setquarter2GreenCount] = useState(0);

  const [quarter3AmberCount, setquarter3AmberCount] = useState(0);
  const [quarter3RedCount, setquarter3RedCount] = useState(0);
  const [quarter3GreenCount, setquarter3GreenCount] = useState(0);

  const [quarter4AmberCount, setquarter4AmberCount] = useState(0);
  const [quarter4RedCount, setquarter4RedCount] = useState(0);
  const [quarter4GreenCount, setquarter4GreenCount] = useState(0);

  const [quarter1OpenCount, setQuarter1OpenCount] = useState(0);
  const [quarter1InprogressCount, setQuarter1InprogressCount] = useState(0);
  const [quarter1CloseCount, setQuarter1CloseCount] = useState(0);

  const [quarter2OpenCount, setQuarter2OpenCount] = useState(0);
  const [quarter2InprogressCount, setQuarter2InprogressCount] = useState(0);
  const [quarter2CloseCount, setQuarter2CloseCount] = useState(0);

  const [quarter3OpenCount, setQuarter3OpenCount] = useState(0);
  const [quarter3InprogressCount, setQuarter3InprogressCount] = useState(0);
  const [quarter3CloseCount, setQuarter3CloseCount] = useState(0);

  const [quarter4OpenCount, setQuarter4OpenCount] = useState(0);
  const [quarter4InprogressCount, setQuarter4InprogressCount] = useState(0);
  const [quarter4CloseCount, setQuarter4CloseCount] = useState(0);

  const MonthArray = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];
  const QuarterArray = ["Quarter 1", "Quarter 2", "Quarter 3", "Quarter 4"];
  let ambercount = 0;
  let redcount = 0;
  let greencount = 0;
  let tableData = [
    ["", "Health", "", "", "Findings", "", "", ""], // Header row (will be merged)
    ["Quarter", "Red", "Amber", "Green", "Open", "In-Progress", "Close"], // Sub-header row
    [
      "Quarter 1",
      quarter1RedCount,
      quarter1AmberCount,
      quarter1GreenCount,
      quarter1OpenCount,
      quarter1InprogressCount,
      quarter1CloseCount,
    ],
    [
      "Quarter 2",
      quarter2RedCount,
      quarter2AmberCount,
      quarter2GreenCount,
      quarter2OpenCount,
      quarter2InprogressCount,
      quarter2CloseCount,
    ],
    [
      "Quarter 3",
      quarter3RedCount,
      quarter3AmberCount,
      quarter3GreenCount,
      quarter3OpenCount,
      quarter3InprogressCount,
      quarter3CloseCount,
    ],
    [
      "Quarter 4",
      quarter4RedCount,
      quarter4AmberCount,
      quarter4GreenCount,
      quarter4OpenCount,
      quarter4InprogressCount,
      quarter4CloseCount,
    ],
  ];
  console.log(tableData);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "accountname") {
      setSelectAccountValue(value);
    } else if (name === "account") {
      setSelectedValue(value);
      setFilterType(name);
    } else if (name === "DU") {
      setSelectedDUValue(value);
      setFilterType(name);
      filterOptions(value);
    } else if (name === "PU") {
      setSelectPUValue(value);
      setFilterType(name);
      filterOptions(selectedDUValue);
    } else if (name === "year") {
      setSelectedYear(value);
      setFilterType(name);
    } else if (name === "month") {
      setSelectedMonth(value);
      setFilterType(name);
    } else if (name === "quarter") {
      setSelectedQuarter(value);
      setFilterType(name);
      setDisabled(value !== "All");
    }
    if (name === "DU" && value === "All") {
      setSelectAccountValue("All");
      setSelectPUValue("All");
    }

    setFlag(true);
  };

  PBTAssessmentData = [
    {
      DU: selectedDUValue,
      PU: selectedPUValue,
      Account: selectedAccountValue,
      Quarter: selectedQuarter || "All",
      Month: selectedMonth || "All",
      Year: selectedYear || "All",
    },
  ];

  const handleDownload = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet1 = XLSX.utils.json_to_sheet(PBTAssessmentData);

    XLSX.utils.book_append_sheet(workbook, worksheet1, "Excel Data");
    const separator = [[""]];
    XLSX.utils.sheet_add_aoa(worksheet1, separator, { origin: -1 });
    XLSX.utils.sheet_add_aoa(worksheet1, tableData, { origin: -1 });
    XLSX.writeFile(workbook, "Tables.xlsx");
    handleClose();
  };
  const filterOptions = (selectedDU: string) => {
    if (selectedDU === "All") {
      setPUoptions(originalPUOptions.map((pu) => pu.pu));
      setAccountoptions(originalAccountOptions.map((acc) => acc.account));
    } else {
      const filteredPU = originalPUOptions
        .filter((pu) => pu.du === selectedDU)
        .map((pu) => pu.pu);
      const filteredAccounts = originalAccountOptions
        .filter((acc) => acc.du === selectedDU)
        .map((acc) => acc.account);
      console.log("Filtered PU:", filteredPU);
      console.log("Filtered Accounts:", filteredAccounts);
      setPUoptions(filteredPU);
      setAccountoptions(filteredAccounts);
    }
  };

  const fetchProjectListData = async () => {
    let DUdata: string[] = [];
    let PUdata: { du: string; pu: string }[] = [];
    let accountdata: { du: string; account: string }[] = [];
    let yearArray: number[] = [];

    const projectList = await axiosInstance.get(
      `${process.env.REACT_APP_DASHBOARD_SERVICE_BASE_URL}/projects`
    );
    const lookupdata = await axiosInstance.get(
      `${process.env.REACT_APP_DASHBOARD_SERVICE_BASE_URL}/lookup`
    );

    console.log("Project List Response:", projectList.data);
    console.log("Lookup Data Response:", lookupdata.data);
    setProjectListData(projectList.data);
    setFlag(true);
    //to push data in dropdown
    projectList.data.forEach((project: any) => {
      if (PUdata.findIndex((p) => p.pu === project.puname) === -1) {
        PUdata.push({ du: project.duname, pu: project.puname });
      }
    });

    if (lookupdata.data) {
      lookupdata.data.forEach((item: any) => {
        if (DUdata.indexOf(item.du) === -1) {
          DUdata.push(item.du);
        }
        if (item.accounts) {
          item.accounts.forEach((acc: any) => {
            if (accountdata.findIndex((a) => a.account === acc) === -1) {
              accountdata.push({ du: item.du, account: acc });
            }
          });
        }
      });
    }
    setDUoptions(DUdata);
    setOriginalPUOptions(PUdata);
    setPUoptions(PUdata.map((pu) => pu.pu));
    setOriginalAccountOptions(accountdata);
    setAccountoptions(accountdata.map((acc) => acc.account));
    //year dropdown array
    let currentDate = new Date();
    yearArray.push(currentDate.getFullYear());
    yearArray.push(currentDate.getFullYear() - 1);
    setYears([...yearArray]);
  };

  useEffect(() => {
    fetchProjectListData();
  }, []);

  //get project-status health from project-assessment-data
  const fetchAssessmentData = async () => {
    let dataOnProjectId;
    //map project-id with projectassesmentdata and get latest health
    dataOnProjectId = await Promise.all(
      projectListData.map(async (item: any) => {
        const projectId = item.projectid;
        const set_name = item.set_name;
        try {
          const assessmentdata = await axiosInstance.get(
            `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/get-projectassesment/${projectId}/${set_name}`
          );

          const projectList = await axiosInstance.get(
            `${process.env.REACT_APP_DASHBOARD_SERVICE_BASE_URL}/projects`
          );
          console.log("assessment Data:", assessmentdata.data);

          const projectDetails = projectList.data.find(
            (project: any) => project.projectid === projectId
          );

          let latestAssessmentDetails = [];
          if (Array.isArray(assessmentdata.data)) {
            latestAssessmentDetails = assessmentdata.data
              .map((element) => {
                if (element.projectid === projectId) {
                  const length = element.assessmentdetails.length;
                  return element.assessmentdetails[length - 1];
                }
              })
              .filter((item) => item);
          } else {
            console.error(
              "assessmentdata.data is not an array:",
              assessmentdata.data
            );
          }
          const combinedResult = latestAssessmentDetails.map((assessment) => ({
            ...assessment,
            projectDetails,
          }));
          console.log("Combined Result:", combinedResult);
          return combinedResult;
        } catch (error) {
          console.error("Error fetching data:", error);
          return null;
        }
      })
    );
    console.log("projectdata", dataOnProjectId);

    if (filterFlag === "year") {
      dataOnProjectId.flat(1).map((item) => {
        console.log("item", item);
        let getyear = new Date(item.assessmentdate).getFullYear();
        let du = item.projectDetails.duname;
        let acc = item.projectDetails.accountname;
        let pu = item.projectDetails.puname;
        console.log("du", du);
        if (
          du == selectedDUValue &&
          acc == selectedAccountValue &&
          pu == selectedPUValue &&
          getyear == selectedYear
        ) {
          return (
            item.projectstatus === "amber"
              ? ambercount++
              : item.projectstatus === "red"
              ? redcount++
              : item.projectstatus === "green"
              ? greencount++
              : redcount,
            greencount,
            ambercount
          );
        }
      });
      setArrayRed([redcount]);
      setArrayAmber([ambercount]);
      setArrayGreen([greencount]);
    } else if (filterFlag === "month") {
      dataOnProjectId.flat(1).map((item) => {
        let getmonth = new Date(item.assessmentdate).getMonth() + 1;
        let du = item.projectDetails.duname;
        let acc = item.projectDetails.accountname;
        let pu = item.projectDetails.puname;
        if (
          du === selectedDUValue &&
          acc === selectedAccountValue &&
          pu === selectedPUValue
        ) {
          if (selectedMonth === "All") {
            return (
              item.projectstatus === "amber"
                ? ambercount++
                : item.projectstatus === "red"
                ? redcount++
                : item.projectstatus === "green"
                ? greencount++
                : redcount,
              greencount,
              ambercount
            );
          } else if (getmonth == Number(selectedMonth)) {
            return (
              item.projectstatus === "amber"
                ? ambercount++
                : item.projectstatus === "red"
                ? redcount++
                : item.projectstatus === "green"
                ? greencount++
                : redcount,
              greencount,
              ambercount
            );
          }
        }
      });
      setArrayRed([redcount]);
      setArrayAmber([ambercount]);
      setArrayGreen([greencount]);
    } else if (filterFlag === "quarter") {
      dataOnProjectId.flat(1).map((item) => {
        let getmonth = new Date(item.assessmentdate).getMonth() + 1;
        let du = item.projectDetails.duname;
        let acc = item.projectDetails.accountname;
        let pu = item.projectDetails.puname;
        console.log("pu", pu);
        console.log("acc", acc);
        console.log("du", du);
        if (
          du === selectedDUValue &&
          acc === selectedAccountValue &&
          pu === selectedPUValue
        ) {
          if (selectedQuarter === "Quarter 1") {
            if (getmonth === 4 || getmonth === 5 || getmonth === 6) {
              return (
                item.projectstatus === "amber"
                  ? ambercount++
                  : item.projectstatus === "red"
                  ? redcount++
                  : item.projectstatus === "green"
                  ? greencount++
                  : redcount,
                greencount,
                ambercount
              );
            }
          }
          if (selectedQuarter === "Quarter 2") {
            if (getmonth === 7 || getmonth === 8 || getmonth === 9) {
              return (
                item.projectstatus === "amber"
                  ? ambercount++
                  : item.projectstatus === "red"
                  ? redcount++
                  : item.projectstatus === "green"
                  ? greencount++
                  : redcount,
                greencount,
                ambercount
              );
            }
          }
          if (selectedQuarter === "Quarter 3") {
            if (getmonth === 10 || getmonth === 11 || getmonth === 12) {
              return (
                item.projectstatus === "amber"
                  ? ambercount++
                  : item.projectstatus === "red"
                  ? redcount++
                  : item.projectstatus === "green"
                  ? greencount++
                  : redcount,
                greencount,
                ambercount
              );
            }
          }
          if (selectedQuarter === "Quarter 4") {
            if (getmonth === 1 || getmonth === 2 || getmonth === 3) {
              return (
                item.projectstatus === "amber"
                  ? ambercount++
                  : item.projectstatus === "red"
                  ? redcount++
                  : item.projectstatus === "green"
                  ? greencount++
                  : redcount,
                greencount,
                ambercount
              );
            }
          } else if (selectedQuarter === "All") {
            return (
              item.projectstatus === "amber"
                ? ambercount++
                : item.projectstatus === "red"
                ? redcount++
                : item.projectstatus === "green"
                ? greencount++
                : redcount,
              greencount,
              ambercount
            );
          }
        } else {
          if (
            (du === selectedDUValue &&
              selectedAccountValue === "All" &&
              selectedPUValue === "All") ||
            (selectedDUValue === "All" &&
              acc === selectedAccountValue &&
              selectedPUValue === "All") ||
            (selectedDUValue === "All" &&
              selectedAccountValue === "All" &&
              pu === selectedPUValue) ||
            (selectedDUValue === "All" &&
              selectedAccountValue === "All" &&
              selectedPUValue === "All")
          ) {
            return (
              item.projectstatus === "amber"
                ? ambercount++
                : item.projectstatus === "red"
                ? redcount++
                : item.projectstatus === "green"
                ? greencount++
                : redcount,
              greencount,
              ambercount
            );
          }
        }
      });
      setArrayRed([redcount]);
      setArrayAmber([ambercount]);
      setArrayGreen([greencount]);
    } else {
      // if (filterFlag === "All") {
      dataOnProjectId.flat(1).map((item) => {
        let du = item.projectDetails.duname;
        let acc = item.projectDetails.accountname;
        let pu = item.projectDetails.puname;
        if (
          du == selectedDUValue ||
          selectedAccountValue === "All" ||
          selectedPUValue === "All" ||
          selectedDUValue === "All" ||
          acc == selectedAccountValue ||
          selectedPUValue === "All" ||
          selectedDUValue === "All" ||
          selectedAccountValue === "All" ||
          pu == selectedPUValue ||
          (selectedDUValue === "All" &&
            selectedAccountValue === "All" &&
            selectedPUValue === "All")
        ) {
          return (
            item.projectstatus === "amber"
              ? ambercount++
              : item.projectstatus === "red"
              ? redcount++
              : item.projectstatus === "green"
              ? greencount++
              : redcount,
            greencount,
            ambercount
          );
        }
      });

      setArrayRed([redcount]);
      setArrayAmber([ambercount]);
      setArrayGreen([greencount]);
    }

    //Total No.of Findings and closed,open,inprogress count
    (async function getProjectFinding() {
      const findingsData = await axiosInstance.get(
        `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/observations`
      );

      const projectList = await axiosInstance.get(
        `${process.env.REACT_APP_DASHBOARD_SERVICE_BASE_URL}/projects`
      );
      console.log("projectDetails", projectList);
      const projectMap = new Map();
      projectList.data.forEach((project: { projectid: any; set: any }) => {
        projectMap.set(project.projectid, project);
      });
      console.log("map", projectMap);
      // Merge project details into findings data
      const mergedData = findingsData.data.map((item: { projectid: any }) => {
        return {
          ...item,
          projectDetails: projectMap.get(item.projectid) || {},
        };
      });
      console.log("mergeData", mergedData);
      //no.of findings
      let findingsCount = 0;
      let openStatusCount = 0;
      let inprogressCount = 0;
      let closeStatusCount = 0;
      console.log("findngs data:", findingsData.data);
      if (filterFlag === "year") {
        mergedData.forEach((item: any) => {
          let du = item.projectDetails.duname;
          let acc = item.projectDetails.accountname;
          let pu = item.projectDetails.puname;
          item.observations.map((observItem: any) => {
            let getyear = new Date(observItem.created_date).getFullYear();
            if (
              du == selectedDUValue &&
              acc == selectedAccountValue &&
              pu === selectedPUValue &&
              selectedYear == getyear
            ) {
              findingsCount++;
              observItem.status === "Open"
                ? openStatusCount++
                : observItem.status === "In Progress"
                ? inprogressCount++
                : observItem.status === "Close"
                ? closeStatusCount++
                : findingsCount++;
            }
          });
          setObservationArray([findingsCount]);
          setOpenStatusArray([openStatusCount]);
          setInprogressArray([inprogressCount]);
          setClosedStatusArray([closeStatusCount]);
        });
      } else if (filterFlag === "month") {
        mergedData.forEach((item: any) => {
          let du = item.projectDetails.duname;
          let acc = item.projectDetails.accountname;
          let pu = item.projectDetails.puname;
          item.observations.forEach((observItem: any) => {
            let getmonth = new Date(observItem.created_date).getMonth() + 1;
            if (
              du === selectedDUValue &&
              acc === selectedAccountValue &&
              pu === selectedPUValue
            ) {
              if (getmonth == Number(selectedMonth)) {
                findingsCount++;
                observItem.status === "Open"
                  ? openStatusCount++
                  : observItem.status === "In Progress"
                  ? inprogressCount++
                  : observItem.status === "Close"
                  ? closeStatusCount++
                  : findingsCount++;
              } else if (selectedMonth === "All") {
                findingsCount++;
                observItem.status === "Open"
                  ? openStatusCount++
                  : observItem.status === "In Progress"
                  ? inprogressCount++
                  : observItem.status === "Close"
                  ? closeStatusCount++
                  : findingsCount++;
              }
            }
          });
          setObservationArray([findingsCount]);
          setOpenStatusArray([openStatusCount]);
          setInprogressArray([inprogressCount]);
          setClosedStatusArray([closeStatusCount]);
        });
      } else if (filterFlag === "quarter") {
        mergedData.forEach((item: any) => {
          let du = item.projectDetails.duname;
          let acc = item.projectDetails.accountname;
          let pu = item.projectDetails.puname;
          item.observations.forEach((observItem: any) => {
            let getmonth = new Date(observItem.created_date).getMonth() + 1;
            if (
              du === selectedDUValue &&
              acc === selectedAccountValue &&
              pu === selectedPUValue
            ) {
              if (selectedQuarter === "Quarter 1") {
                if (getmonth == 4 || getmonth == 5 || getmonth == 6) {
                  findingsCount++;
                  observItem.status === "Open"
                    ? openStatusCount++
                    : observItem.status === "In Progress"
                    ? inprogressCount++
                    : observItem.status === "Close"
                    ? closeStatusCount++
                    : findingsCount++;
                }
              } else if (selectedQuarter === "Quarter 2") {
                if (getmonth === 7 || getmonth === 8 || getmonth === 9) {
                  findingsCount++;
                  observItem.status === "Open"
                    ? openStatusCount++
                    : observItem.status === "In Progress"
                    ? inprogressCount++
                    : observItem.status === "Close"
                    ? closeStatusCount++
                    : findingsCount++;
                }
              } else if (selectedQuarter === "Quarter 3") {
                if (getmonth === 10 || getmonth === 11 || getmonth === 12) {
                  findingsCount++;
                  observItem.status === "Open"
                    ? openStatusCount++
                    : observItem.status === "In Progress"
                    ? inprogressCount++
                    : observItem.status === "Close"
                    ? closeStatusCount++
                    : findingsCount++;
                }
              } else if (selectedQuarter === "Quarter 4") {
                if (getmonth === 1 || getmonth === 2 || getmonth === 3) {
                  findingsCount++;
                  observItem.status === "Open"
                    ? openStatusCount++
                    : observItem.status === "In Progress"
                    ? inprogressCount++
                    : observItem.status === "Close"
                    ? closeStatusCount++
                    : findingsCount++;
                }
              } else if (selectedQuarter === "All") {
                findingsCount++;
                observItem.status === "Open"
                  ? openStatusCount++
                  : observItem.status === "In Progress"
                  ? inprogressCount++
                  : observItem.status === "Close"
                  ? closeStatusCount++
                  : findingsCount++;
              }
            } else {
              if (
                (du === selectedDUValue &&
                  selectedAccountValue === "All" &&
                  selectedPUValue === "All") ||
                (selectedDUValue === "All" &&
                  acc === selectedAccountValue &&
                  selectedPUValue === "All") ||
                (selectedDUValue === "All" &&
                  selectedAccountValue === "All" &&
                  pu === selectedPUValue) ||
                (selectedDUValue === "All" &&
                  selectedAccountValue === "All" &&
                  selectedPUValue === "All")
              ) {
                findingsCount++;
                observItem.status === "Open"
                  ? openStatusCount++
                  : observItem.status === "In Progress"
                  ? inprogressCount++
                  : observItem.status === "Close"
                  ? closeStatusCount++
                  : findingsCount++;
              }
            }
            setObservationArray([findingsCount]);
            setOpenStatusArray([openStatusCount]);
            setInprogressArray([inprogressCount]);
            setClosedStatusArray([closeStatusCount]);
          });
        });
      } else {
        mergedData.forEach((item: any) => {
          let du = item.projectDetails.duname;
          let acc = item.projectDetails.accountname;
          let pu = item.projectDetails.puname;
          item.observations.forEach((observItem: any) => {
            if (
              du == selectedDUValue ||
              selectedAccountValue === "All" ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              acc == selectedAccountValue ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              selectedAccountValue === "All" ||
              pu == selectedPUValue ||
              (selectedDUValue === "All" &&
                selectedAccountValue === "All" &&
                selectedPUValue === "All")
            ) {
              findingsCount++;
              observItem.status === "Open"
                ? openStatusCount++
                : observItem.status === "In Progress"
                ? inprogressCount++
                : observItem.status === "Close"
                ? closeStatusCount++
                : findingsCount++;
            }
          });
          setObservationArray([findingsCount]);
          setOpenStatusArray([openStatusCount]);
          setInprogressArray([inprogressCount]);
          setClosedStatusArray([closeStatusCount]);
        });
      }

      //Total Number of projects completed.
      (async function getCompletedProjectCount() {
        let count = 0;
        let assessmentdata: any = [];
        let projectCount = 0;
        const assessmentdetails = await Promise.all(
          projectListData.map(async (item: any) => {
            console.log("hello", item);
            console.log("Status", item.status);
            if (item.status === "active") {
              const projectId = item.projectid;
              const set_name = item.set_name;
              assessmentdata = await axiosInstance.get(
                `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/get-projectassesment/${projectId}/${set_name}`
              );
            }
            if (assessmentdata.data?.length > 0) {
              count++;
              return assessmentdata.data[0].assessmentdetails;
            }
            console.log("assessment data", assessmentdata);
            console.log("count", count);
          })
        );
        setCompletedProject([count]);
        const data = assessmentdetails.filter((item) => item !== undefined);
        console.log("data", data);

        data.flat(1).map((item: any) => {
          let getmonth = new Date(item.assessmentdate).getMonth() + 1;
          if (filterFlag === "year") {
            let getyear = new Date(item.assessmentdate).getFullYear();
            if (selectedYear == getyear) {
              projectCount++;
            }
            setCompletedProject([projectCount]);
          } else if (filterFlag === "month") {
            let getmonth = new Date(item.assessmentdate).getMonth() + 1;
            if (getmonth == selectedMonth) {
              projectCount++;
            } else if (selectedMonth === "All") {
              projectCount++;
            }
            setCompletedProject([projectCount]);
          } else if (filterFlag === "quarter") {
            if (selectedQuarter === "Quarter 1") {
              if (getmonth === 4 || getmonth === 5 || getmonth === 6) {
                projectCount++;
                setCompletedProject([projectCount]);
              }
            } else if (selectedQuarter === "Quarter 2") {
              if (getmonth === 7 || getmonth === 8 || getmonth === 9) {
                projectCount++;
              } else {
                projectCount = 0;
              }
              setCompletedProject([projectCount]);
            } else if (selectedQuarter === "Quarter 3") {
              if (getmonth === 10 || getmonth === 11 || getmonth === 12) {
                projectCount++;
              } else {
                projectCount = 0;
              }
              setCompletedProject([projectCount]);
            } else if (selectedQuarter === "Quarter 4") {
              if (getmonth === 1 || getmonth === 2 || getmonth === 3) {
                projectCount++;
              } else {
                projectCount = 0;
              }
              setCompletedProject([projectCount]);
            } else if (selectedQuarter === "All") {
              projectCount++;
              setCompletedProject([projectCount]);
            }
          }
        });
      })();

      (async function getTableData() {
        let quarter1AmberCounts = 0;
        let quarter2AMbercounts = 0;
        let quarter3AmberCounts = 0;
        let quarter4AmberCounts = 0;
        let quarter1RedCounts = 0;
        let quarter2RedCounts = 0;
        let quarter3RedCounts = 0;
        let quarter4RedCounts = 0;
        let quarter1GreenCounts = 0;
        let quarter2GreenCounts = 0;
        let quarter3GreenCounts = 0;
        let quarter4GreenCounts = 0;

        console.log("data on project id", dataOnProjectId);
        dataOnProjectId.flat(1).map((item: any) => {
          const getMonth = new Date(item.assessmentdate).getMonth() + 1;
          let du = item.projectDetails.duname;
          let acc = item.projectDetails.accountname;
          let pu = item.projectDetails.puname;
          if (
            (du == selectedDUValue ||
              selectedAccountValue === "All" ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              acc == selectedAccountValue ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              selectedAccountValue === "All" ||
              pu == selectedPUValue ||
              (selectedDUValue === "All" &&
                selectedAccountValue === "All" &&
                selectedPUValue === "All")) &&
            (getMonth == 4 || getMonth == 5 || getMonth === 6) &&
            item.projectstatus === "amber"
          ) {
            quarter1AmberCounts++;
          } else if (
            (du == selectedDUValue ||
              selectedAccountValue === "All" ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              acc == selectedAccountValue ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              selectedAccountValue === "All" ||
              pu == selectedPUValue ||
              (selectedDUValue === "All" &&
                selectedAccountValue === "All" &&
                selectedPUValue === "All")) &&
            (getMonth == 7 || getMonth === 8 || getMonth === 9) &&
            item.projectstatus === "amber"
          ) {
            quarter2AMbercounts++;
          } else if (
            (du == selectedDUValue ||
              selectedAccountValue === "All" ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              acc == selectedAccountValue ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              selectedAccountValue === "All" ||
              pu == selectedPUValue ||
              (selectedDUValue === "All" &&
                selectedAccountValue === "All" &&
                selectedPUValue === "All")) &&
            (getMonth == 10 || getMonth == 11 || getMonth == 12) &&
            item.projectstatus === "amber"
          ) {
            quarter3AmberCounts++;
          } else if (
            (du == selectedDUValue ||
              selectedAccountValue === "All" ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              acc == selectedAccountValue ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              selectedAccountValue === "All" ||
              pu == selectedPUValue ||
              (selectedDUValue === "All" &&
                selectedAccountValue === "All" &&
                selectedPUValue === "All")) &&
            (getMonth == 1 || getMonth == 2 || getMonth == 3) &&
            item.projectstatus === "amber"
          ) {
            quarter4AmberCounts++;
          } else if (
            (du == selectedDUValue ||
              selectedAccountValue === "All" ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              acc == selectedAccountValue ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              selectedAccountValue === "All" ||
              pu == selectedPUValue ||
              (selectedDUValue === "All" &&
                selectedAccountValue === "All" &&
                selectedPUValue === "All")) &&
            (getMonth == 4 || getMonth == 5 || getMonth == 6) &&
            item.projectstatus === "red"
          ) {
            quarter1RedCounts++;
          } else if (
            (du == selectedDUValue ||
              selectedAccountValue === "All" ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              acc == selectedAccountValue ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              selectedAccountValue === "All" ||
              pu == selectedPUValue ||
              (selectedDUValue === "All" &&
                selectedAccountValue === "All" &&
                selectedPUValue === "All")) &&
            (getMonth == 7 || getMonth == 8 || getMonth == 9) &&
            item.projectstatus === "red"
          ) {
            quarter2RedCounts++;
          } else if (
            (du == selectedDUValue ||
              selectedAccountValue === "All" ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              acc == selectedAccountValue ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              selectedAccountValue === "All" ||
              pu == selectedPUValue ||
              (selectedDUValue === "All" &&
                selectedAccountValue === "All" &&
                selectedPUValue === "All")) &&
            (getMonth == 10 || getMonth == 11 || getMonth == 12) &&
            item.projectstatus === "red"
          ) {
            quarter3RedCounts++;
          } else if (
            (du == selectedDUValue ||
              selectedAccountValue === "All" ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              acc == selectedAccountValue ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              selectedAccountValue === "All" ||
              pu == selectedPUValue ||
              (selectedDUValue === "All" &&
                selectedAccountValue === "All" &&
                selectedPUValue === "All")) &&
            (getMonth == 1 || getMonth == 2 || getMonth == 3) &&
            item.projectstatus === "red"
          ) {
            quarter4RedCounts++;
          } else if (
            (du == selectedDUValue ||
              selectedAccountValue === "All" ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              acc == selectedAccountValue ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              selectedAccountValue === "All" ||
              pu == selectedPUValue ||
              (selectedDUValue === "All" &&
                selectedAccountValue === "All" &&
                selectedPUValue === "All")) &&
            (getMonth == 4 || getMonth == 5 || getMonth == 6) &&
            item.projectstatus == "green"
          ) {
            quarter1GreenCounts++;
          } else if (
            (du == selectedDUValue ||
              selectedAccountValue === "All" ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              acc == selectedAccountValue ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              selectedAccountValue === "All" ||
              pu == selectedPUValue ||
              (selectedDUValue === "All" &&
                selectedAccountValue === "All" &&
                selectedPUValue === "All")) &&
            (getMonth == 7 || getMonth == 8 || getMonth == 9) &&
            item.projectstatus == "green"
          ) {
            quarter2GreenCounts++;
          } else if (
            (du == selectedDUValue ||
              selectedAccountValue === "All" ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              acc == selectedAccountValue ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              selectedAccountValue === "All" ||
              pu == selectedPUValue ||
              (selectedDUValue === "All" &&
                selectedAccountValue === "All" &&
                selectedPUValue === "All")) &&
            (getMonth == 10 || getMonth == 11 || getMonth == 12) &&
            item.projectstatus == "green"
          ) {
            quarter3GreenCounts++;
          } else if (
            (du == selectedDUValue ||
              selectedAccountValue === "All" ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              acc == selectedAccountValue ||
              selectedPUValue === "All" ||
              selectedDUValue === "All" ||
              selectedAccountValue === "All" ||
              pu == selectedPUValue ||
              (selectedDUValue === "All" &&
                selectedAccountValue === "All" &&
                selectedPUValue === "All")) &&
            (getMonth == 1 || getMonth == 2 || getMonth == 3) &&
            item.projectstatus == "green"
          ) {
            quarter4GreenCounts++;
          }
        });

        setquarter1AmberCount(quarter1AmberCounts);
        setquarter1GreenCount(quarter1GreenCounts);
        setquarter1RedCount(quarter1RedCounts);

        setquarter2AmberCount(quarter2AMbercounts);
        setquarter2GreenCount(quarter2GreenCounts);
        setquarter2RedCount(quarter2RedCounts);

        setquarter3AmberCount(quarter3AmberCounts);
        setquarter3GreenCount(quarter3GreenCounts);
        setquarter3RedCount(quarter3RedCounts);

        setquarter4AmberCount(quarter4AmberCounts);
        setquarter4GreenCount(quarter4GreenCounts);
        setquarter4RedCount(quarter4RedCounts);

        let quarter1OpenCounts = 0;
        let quarter2OpenCounts = 0;
        let quarter3OpenCounts = 0;
        let quarter4OpenCounts = 0;
        let quarter1CloseCounts = 0;
        let quarter2CloseCounts = 0;
        let quarter3CloseCounts = 0;
        let quarter4CloseCounts = 0;
        let quarter1InprogressCounts = 0;
        let quarter2InprogressCounts = 0;
        let quarter3InprogressCounts = 0;
        let quarter4InprogressCounts = 0;
        console.log("observ", observationData);
        mergedData.forEach((item: any) => {
          const du = item.projectDetails.duname;
          const acc = item.projectDetails.accountname;
          const pu = item.projectDetails.puname;
          if (
            du == selectedDUValue ||
            selectedAccountValue === "All" ||
            selectedPUValue === "All" ||
            selectedDUValue === "All" ||
            acc == selectedAccountValue ||
            selectedPUValue === "All" ||
            selectedDUValue === "All" ||
            selectedAccountValue === "All" ||
            pu == selectedPUValue ||
            (selectedDUValue === "All" &&
              selectedAccountValue === "All" &&
              selectedPUValue === "All")
          ) {
            item.observations.forEach((element: any) => {
              const getmonth = new Date(element.created_date).getMonth() + 1;

              if (element.status === "Open") {
                if (getmonth == 4 || getmonth == 5 || getmonth == 6) {
                  quarter1OpenCounts++;
                } else if (getmonth == 7 || getmonth == 8 || getmonth == 9) {
                  quarter2OpenCounts++;
                } else if (getmonth == 10 || getmonth == 11 || getmonth == 12) {
                  quarter3OpenCounts++;
                } else if (getmonth == 1 || getmonth == 2 || getmonth == 3) {
                  quarter4OpenCounts++;
                }
              } else if (element.status === "Close") {
                if (getmonth == 4 || getmonth == 5 || getmonth == 6) {
                  quarter1CloseCounts++;
                } else if (getmonth == 7 || getmonth == 8 || getmonth == 9) {
                  quarter2CloseCounts++;
                } else if (getmonth == 10 || getmonth == 11 || getmonth == 12) {
                  quarter3CloseCounts++;
                } else if (getmonth == 1 || getmonth == 2 || getmonth == 3) {
                  quarter4CloseCounts++;
                }
              } else if (element.status === "In Progress") {
                if (getmonth == 4 || getmonth == 5 || getmonth == 6) {
                  quarter1InprogressCounts++;
                } else if (getmonth == 7 || getmonth == 8 || getmonth == 9) {
                  quarter2InprogressCounts++;
                } else if (getmonth == 10 || getmonth == 11 || getmonth == 12) {
                  quarter3InprogressCounts++;
                } else if (getmonth == 1 || getmonth == 2 || getmonth == 3) {
                  quarter4InprogressCounts++;
                }
              }
            });
          }
        });
        setQuarter1OpenCount(quarter1OpenCounts);
        setQuarter1InprogressCount(quarter1InprogressCounts);
        setQuarter1CloseCount(quarter1CloseCounts);

        setQuarter2OpenCount(quarter2OpenCounts);
        setQuarter2InprogressCount(quarter2InprogressCounts);
        setQuarter2CloseCount(quarter2CloseCounts);

        setQuarter3OpenCount(quarter3OpenCounts);
        setQuarter3InprogressCount(quarter3InprogressCounts);
        setQuarter3CloseCount(quarter3CloseCounts);

        setQuarter4OpenCount(quarter4OpenCounts);
        setQuarter4InprogressCount(quarter4InprogressCounts);
        setQuarter4CloseCount(quarter4CloseCounts);
      })();
    })();
  };

  const contentRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExport = () => {
    const element = contentRef.current;
    exportToPDF(element);
    handleClose();
  };

  useEffect(() => {
    fetchAssessmentData();
  }, [
    selectedDUValue,
    selectedAccountValue,
    selectedPUValue,
    selectedQuarter,
    flag,
    selectedYear,
    selectedMonth,
  ]);
  type OptionType = {
    value: string;
    label: string;
  };

  return (
    <div className="leadershipReportContainer">
      <Navbar />
      <div className="app-pageSize">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <div className="app-pageHeader">Reports</div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", flex: 1, columnGap: "9%" }}>
                  <label className="shortlabel">Select the DU:</label>
                  <select
                    className="options"
                    name="DU"
                    style={{ translate: "-2px 0px" }}
                    onChange={handleChange}
                    value={selectedDUValue} // Use value for controlled component
                  >
                    <option>All</option>
                    {duOptions.map((item: any, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>

                <div style={{ display: "flex", flex: 1 }}>
                  <label className="shortlabel">Select the Account:</label>
                  <select
                    className="options"
                    name="accountname"
                    onChange={handleChange}
                    value={selectedAccountValue} // Use value for controlled component
                  >
                    <option>All</option>
                    {accountOptions.map((item: any, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>

                <div style={{ display: "flex", flex: 1, columnGap: "30px" }}>
                  <label className="shortlabel">Select the PU:</label>
                  <select
                    className="options"
                    name="PU"
                    onChange={handleChange}
                    value={selectedPUValue} // Use value for controlled component
                  >
                    <option>All</option>
                    {puOptions.map((pu, index) => (
                      <option key={index} value={pu}>
                        {pu}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", flex: 1 }}>
                  <label className="shortlabel">Select the Quarter:</label>

                  <select
                    className="options"
                    style={{ translate: "2px 0px" }}
                    name="quarter"
                    onChange={handleChange}
                  >
                    <option value="All">All</option>
                    {QuarterArray.map((item: any, index) => (
                      <option key={index}>{item}</option>
                    ))}
                  </select>
                </div>
                <div style={{ display: "flex", flex: 1, columnGap: "2%" }}>
                  <label className="shortlabel">Select the Month:</label>
                  <select
                    className="options"
                    style={{ translate: "7px 0px" }}
                    name="month"
                    disabled={isDisabled}
                    onChange={handleChange}
                  >
                    <option>All</option>
                    {MonthArray.map((item: any, index) => (
                      <option key={index} value={index + 1}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ display: "flex", flex: 1, columnGap: "28px" }}>
                  <label className="shortlabel">Select the Year:</label>
                  <select
                    className="options"
                    style={{ translate: "-8px 0px" }}
                    name="year"
                    onChange={handleChange}
                    disabled={isDisabled}
                  >
                    <option style={{ display: "none" }}>2024</option>
                    {yearArray.map((item: any, index) => (
                      <option key={index}>{item}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <div style={{ translate: "850px" }}>
                  <Button
                    variant="contained"
                    onClick={handleClick}
                    style={{ backgroundColor: "#2f3a74" }}
                    endIcon={<ArrowDropDownIcon />}
                  >
                    Download
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleDownload}>
                      Export to Excel
                    </MenuItem>
                    <MenuItem onClick={handleExport}>Export to PDF</MenuItem>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* FIRST CHART */}
        <div
          style={{
            display: "grid",
            grid: "500px/auto auto",
            columnGap: "30px",
            rowGap: "10px",
          }}
          ref={contentRef}
        >
          <div style={{ flex: 1 }}>
            {/* Chart div for latest health*/}
            <div className="chartDiv">
              <div>
                <Bar
                  data={{
                    // Name of the variables on x-axis for each bar
                    labels: [""],
                    datasets: [
                      {
                        // Label for bars
                        label: "",
                        // Data or value of your each variable
                        data: arrayGreen,
                        // Color of each bar
                        backgroundColor: "#008000",
                        // Border color of each bar
                        borderColor: "#008000",
                        borderWidth: 0.5,
                        maxBarThickness: 100,
                        //minBarLength:6,
                        barPercentage: 0.4,
                      },
                      {
                        // Label for bars
                        label: "Amber",
                        // Data or value of your each variable
                        data: arrayAmber,
                        // Color of each bar
                        backgroundColor: "#FFA500",
                        // Border color of each bar
                        borderColor: "#FFA500",
                        borderWidth: 0.5,
                        maxBarThickness: 100,
                        //minBarLength:6,
                        barPercentage: 0.4,
                      },
                      {
                        // Label for bars
                        label: "Red",
                        // Data or value of your each variable
                        data: arrayRed,
                        // Color of each bar
                        backgroundColor: "#FF0000",
                        // Border color of each bar
                        borderColor: "#FF0000",
                        borderWidth: 0.5,
                        maxBarThickness: 100,
                        //minBarLength:6,
                        barPercentage: 0.4,
                      },
                    ],
                  }}
                  // Height of graph
                  height={240}
                  width={500}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                        position: "bottom",
                        labels: {
                          usePointStyle: true,
                        },
                      },
                      datalabels: {
                        color: "white",
                        anchor: "end",
                        align: "end",
                      },
                    },

                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        display: false,
                        //beginAtZero:true
                      },
                      x: {
                        border: {
                          color: "#FFFFFF",
                        },
                        ticks: {
                          color: "#FFFFFF",
                        },
                      },
                      customGroups: {
                        axis: "x",
                        labels: ["Project Health Report"],
                        grid: {
                          drawOnChartArea: false,
                          //lineWidth: [1, 1, 0, 0, 1, 1],
                        },
                        ticks: {
                          //maxRotation: 90,
                          //minRotation: 90,
                          labelOffset: 20,
                          color: "white",
                          font: { size: 14 },
                        },
                      },
                    },

                    layout: {
                      padding: {
                        left: 40,
                        right: 80,
                        top: 30,
                        bottom: 10,
                      },
                    },
                  }}
                />
              </div>
              <ul
                style={{
                  padding: "0px",
                  margin: "0px",
                  color: "#ffffff",
                  position: "absolute",
                  left: "85%",
                }}
              >
                <div style={{ display: "flex", padding: "2px" }}>
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: "#FF0000",
                      marginRight: "6px",
                    }}
                  ></div>{" "}
                  <div style={{ fontSize: "12px" }} className="Subbmissin">
                    Red
                  </div>
                </div>
                <div style={{ display: "flex", padding: "2px" }}>
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: "#FFA500",
                      marginRight: "6px",
                    }}
                  ></div>{" "}
                  <div style={{ fontSize: "12px" }} className="Subbmissin">
                    Amber
                  </div>
                </div>
                <div style={{ display: "flex", padding: "2px" }}>
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: "#008000",
                      marginRight: "6px",
                    }}
                  ></div>{" "}
                  <div style={{ fontSize: "12px" }} className="Subbmissin">
                    Green{" "}
                  </div>
                </div>
              </ul>
            </div>
          </div>

          {/* SECOND CHART  */}

          {/* chart div for findings */}
          <div style={{ flex: 1, width: "500px" }}>
            <div className="chartDiv">
              <div>
                <Bar
                  data={{
                    labels: [""],
                    datasets: [
                      {
                        // Label for bars
                        label: "No.of Findings",
                        // Data or value of your each variable
                        data: observationsArray,
                        // Color of each bar
                        backgroundColor: "#FFA500",
                        // Border color of each bar
                        borderColor: "#FFA500",
                        borderWidth: 0.5,
                        maxBarThickness: 100,
                        //minBarLength:6,
                        barPercentage: 0.5,
                      },
                      {
                        // Label for bars
                        label: "Closed status",
                        // Data or value of your each variable
                        data: closedStatusDataset,
                        // Color of each bar
                        backgroundColor: "pink",
                        // Border color of each bar
                        //borderColor: ["#FFA500", "#008000", "#FF0000"],
                        // borderWidth: 0.5,
                        maxBarThickness: 100,
                        //minBarLength:6,
                        barPercentage: 0.5,
                      },
                      {
                        // Label for bars
                        label: "Open status",
                        // Data or value of your each variable
                        data: openStatusDataset,
                        // Color of each bar
                        backgroundColor: "violet",
                        // Border color of each bar
                        //borderColor: ["#FFA500", "#008000", "#FF0000"],
                        // borderWidth: 0.5,
                        maxBarThickness: 100,
                        //minBarLength:6,
                        barPercentage: 0.5,
                      },
                      {
                        // Label for bars
                        label: "In-Progress status",
                        // Data or value of your each variable
                        data: inprogressDataset,
                        // Color of each bar
                        backgroundColor: "#008000",
                        // Border color of each bar
                        //borderColor: ["#FFA500", "#008000", "#FF0000"],
                        // borderWidth: 0.5,
                        maxBarThickness: 100,
                        //minBarLength:6,
                        barPercentage: 0.5,
                      },
                    ],
                  }}
                  // Height of graph
                  height={240}
                  width={500}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                        position: "bottom",
                        labels: {
                          usePointStyle: true,
                        },
                      },
                      datalabels: {
                        color: "white",
                        anchor: "end",
                        align: "end",
                      },
                    },

                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        display: false,
                        //beginAtZero:true
                      },
                      x: {
                        border: {
                          color: "#FFFFFF",
                        },
                        ticks: {
                          color: "#FFFFFF",
                        },
                      },
                      customGroups: {
                        axis: "x",
                        labels: ["Number Of Findings Shared Report"],
                        grid: {
                          drawOnChartArea: false,
                        },
                        ticks: {
                          labelOffset: 20,
                          color: "white",
                          font: { size: 14 },
                        },
                      },
                    },
                    layout: {
                      padding: {
                        left: 40,
                        right: 90,
                        top: 30,
                        bottom: 10,
                      },
                    },
                  }}
                />
              </div>
              <ul
                style={{
                  padding: "0px",
                  margin: "0px",
                  color: "#ffffff",
                  position: "absolute",
                  left: "80%",
                  width: "27%",
                }}
              >
                <div style={{ display: "flex", padding: "2px" }}>
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: "#FFA500",
                      marginRight: "6px",
                    }}
                  ></div>{" "}
                  <div style={{ fontSize: "12px" }} className="Subbmissin">
                    No of Findings
                  </div>
                </div>
                <div style={{ display: "flex", padding: "2px" }}>
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: "pink",
                      marginRight: "6px",
                    }}
                  ></div>{" "}
                  <div style={{ fontSize: "12px" }} className="Subbmissin">
                    Closed Status
                  </div>
                </div>
                <div style={{ display: "flex", padding: "2px" }}>
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: "violet",
                      marginRight: "6px",
                    }}
                  ></div>{" "}
                  <div style={{ fontSize: "12px" }} className="Subbmissin">
                    Open status
                  </div>
                </div>
                <div style={{ display: "flex", padding: "2px" }}>
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: "#008000",
                      marginRight: "6px",
                    }}
                  ></div>{" "}
                  <div style={{ fontSize: "12px" }} className="Subbmissin">
                    In Progress
                  </div>
                </div>
              </ul>
            </div>
          </div>

          {/* THIRD CHART */}
          <div style={{ flex: 1, width: "550px", translate: "0px -180px" }}>
            {/* No of completed projects */}
            <div className="chartParent">
              <div className="chartDiv">
                <div>
                  <Bar
                    data={{
                      // Name of the variables on x-axis for each bar
                      labels: [""],
                      datasets: [
                        {
                          // Label for bars
                          label: "Range",
                          // Data or value of your each variable
                          data: completedProjectCount,
                          // Color of each bar
                          backgroundColor: "#479cc7",
                          // Border color of each bar
                          // borderColor: ["#FFA500", "#008000", "#FF0000"],
                          borderWidth: 0.5,
                          maxBarThickness: 60,
                          //minBarLength:6,
                          barPercentage: 0.3,
                        },
                      ],
                    }}
                    // Height of graph
                    height={240}
                    width={500}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                          position: "bottom",
                          labels: {
                            usePointStyle: true,
                          },
                        },
                        datalabels: {
                          color: "white",
                          anchor: "end",
                          align: "end",
                        },
                      },

                      maintainAspectRatio: false,
                      scales: {
                        y: {
                          display: false,
                          //beginAtZero:true
                        },
                        x: {
                          border: {
                            color: "#FFFFFF",
                          },
                          ticks: {
                            color: "#FFFFFF",
                          },
                        },
                        customGroups: {
                          axis: "x",
                          labels: ["Completed Project Report"],
                          grid: {
                            drawOnChartArea: false,
                          },
                          ticks: {
                            labelOffset: 10,
                            color: "white",
                            font: { size: 14 },
                          },
                        },
                      },
                      layout: {
                        padding: {
                          left: 130,
                          right: 130,
                          top: 40,
                          bottom: 10,
                        },
                      },
                    }}
                  />
                </div>
                <ul
                  style={{
                    padding: "0px",
                    margin: "0px",
                    color: "#ffffff",
                    position: "absolute",
                    left: "70%",
                  }}
                >
                  <div style={{ display: "flex", padding: "5px" }}>
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        backgroundColor: "#479cc7",
                        marginRight: "6px",
                      }}
                    ></div>{" "}
                    <div style={{ fontSize: "12px" }} className="Subbmissin">
                      completed projects
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>

          {/* FOURTH CHART */}
          <div style={{ flex: 1, width: "500px", translate: "0px -180px" }}>
            {/* summary in table */}
            <table className="tstyle">
              <thead style={{ lineHeight: "24px" }}>
                <tr>
                  <th colSpan={4}>Health</th>
                  <th colSpan={4}>Findings</th>
                </tr>
                <tr>
                  <th>Quarter</th>
                  <th>Red </th>
                  <th>Amber </th>
                  <th>Green </th>
                  <th>Open</th>
                  <th>In-Progress</th>
                  <th>Close</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Quarter 1</td>
                  <td>{quarter1RedCount}</td>
                  <td>{quarter1AmberCount}</td>
                  <td>{quarter1GreenCount}</td>

                  <td>{quarter1OpenCount}</td>
                  <td>{quarter1InprogressCount}</td>
                  <td>{quarter1CloseCount}</td>
                </tr>
                <tr>
                  <td>Quarter 2</td>
                  <td>{quarter2RedCount}</td>
                  <td>{quarter2AmberCount}</td>
                  <td>{quarter2GreenCount}</td>

                  <td>{quarter2OpenCount}</td>
                  <td>{quarter2InprogressCount}</td>
                  <td>{quarter2CloseCount}</td>
                </tr>
                <tr>
                  <td>Quarter 3</td>
                  <td>{quarter3RedCount}</td>
                  <td>{quarter3AmberCount}</td>
                  <td>{quarter3GreenCount}</td>

                  <td>{quarter3OpenCount}</td>
                  <td>{quarter3InprogressCount}</td>
                  <td>{quarter3CloseCount}</td>
                </tr>
                <tr>
                  <td>Quarter 4</td>
                  <td>{quarter4RedCount}</td>
                  <td>{quarter4AmberCount}</td>
                  <td>{quarter4GreenCount}</td>

                  <td>{quarter4OpenCount}</td>
                  <td>{quarter4InprogressCount}</td>
                  <td>{quarter4CloseCount}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadershipReport;
