import React, { useEffect, useState } from "react";
import "./attachment.scss";
import axiosInstance from "../../../../../httpInterceptor";
import { useParams } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faDownload } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import { FaFilePdf, FaFileWord, FaFileAlt, FaFile } from "react-icons/fa";
pdfjs.GlobalWorkerOptions.workerSrc = "/js/pdf.worker.min copy.mjs";

Modal.setAppElement("#root");

interface FileUploadProps {
  projectid?: string;
  setName?: string;
  emails: string[];
}
interface UploadedFile {
  _id: string;
  category: string;
  url: string[];
  type: string;
}
interface FileRecord {
  projectid: string;
  set_name: string;
  files: UploadedFile[];
}
const FileUpload: React.FC<FileUploadProps> = ({
  projectid,
  setName,
  emails,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [filesList, setFilesList] = useState<UploadedFile[]>([]);
  const [category, setCategory] = useState("");
  const [customCategory, setCustomCategory] = useState("");
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [cloudFrontUrls, setCloudFrontUrls] = useState<string[]>([]);
  const [error, setError] = useState("");
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);
  const [emailAdresses, setEmailAddress] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showFullFileName, setShowFullFileName] = useState<{
    [key: number]: boolean;
  }>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/files/${projectid}/${setName}`
      );
      const fileRecords: FileRecord[] = response.data || [];
      const allFiles = fileRecords.flatMap((record) => record.files);
      setFilesList(allFiles);
      console.log(allFiles);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };
  console.log(filesList[0]);

  const allowedFileTypes = [
    "application/pdf",
    "text/plain",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []);
    const validFiles = selectedFiles.filter((file: File) =>
      allowedFileTypes.includes(file.type)
    );
    if (validFiles.length !== selectedFiles.length) {
      setError(
        "Some files have invalid types. Only PDF, TXT, DOC, DOCX,PPTX files are allowed."
      );
    } else {
      setError("");
    }
    setFiles(validFiles as File[]);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailAddress(e.target.value);
  };

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectCategory = e.target.value;
    if (selectCategory === "Other") {
      setShowCustomInput(true);
      setCategory("");
    } else {
      setShowCustomInput(false);
      setCategory(selectCategory);
    }
  };
  const handleCustomCategoryChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomCategory(e.target.value);
  };

  const handleCustomCategorySubmit = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    setCategory(customCategory);
  };
  const handleFileUpload = async (withEmail: boolean) => {
    if (files.length === 0) {
      setError("No files selected.");
      return;
    }
    if (!projectid) {
      setError("Project ID is required.");
      return;
    }

    if (!setName) {
      setError("Set name is required.");
      return;
    }
    console.log(category);
    let uploadedUrls: string[] = [];
    let sendingUrls: string[] = [];
    const previewUrls: string[] = [];
    setLoading(true);
    try {
      for (const file of files) {
        const formData = new FormData();
        formData.append("Files", file);
        formData.append("category", category);
        console.log(file);
        formData.append("projectid", projectid);
        formData.append("set_name", setName);
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/upload/${projectid}/${setName}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data) {
          toast.success("File successfully uploaded");
          // window.location.reload();
          console.log(formData);
          console.log(response);
          uploadedUrls.push(response.data.cloudFrontUrls);
          previewUrls.push(URL.createObjectURL(file));
          console.log(uploadedUrls);
        }
      }
      if (withEmail) {
        const emailPayload = {
          projectid,
          setName,
          toEmail: emails,
          emailType: "file",
          files: uploadedUrls,
        };
        const res = await axiosInstance.post(
          `${process.env.REACT_APP_EMAIL_SERVICE_BASE_URL}/email`,
          emailPayload,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (res.data) {
          toast.success(
            "Email sent successfully. Thank you, check your email."
          );
        }
      }
      window.location.reload();
      setLoading(false);
    } catch (error: any) {
      console.error("Error uploading file:", error);

      if (error.response && error.response.data) {
        console.log(error.response.data);
        setError(error.response.data);
        console.log(error);
      } else {
        setError("Error uploading file.");
      }
    }

    setCloudFrontUrls(uploadedUrls);
    setPreviewUrls(previewUrls);
    // setError("");
    console.log("Files uploaded successfully:", uploadedUrls);
    console.log(setPreviewUrls);
  };

  const handleDelete = async (_id: string) => {
    await axiosInstance.delete(
      `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/delete/${_id}`
    );
    toast.success("File deleted successfully");
    fetchFiles();
  };

  const handleDownload = async (_id: string) => {
    console.log("Downloading file with id:", _id);
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/files/download/${_id}`,
      {
        responseType: "blob",
      }
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "file.pdf"); // or extract the file name from the response
    document.body.appendChild(link);
    link.click();
  };

  const getFileName = (url: string) => {
    return url.split("/").pop();
  };
  const toggleFullFileName = (index: number) => {
    setShowFullFileName((prevState) => ({
      [index]: !prevState[index],
    }));
  };

  const getFileIcon = (fileType: any): JSX.Element => {
    switch (fileType) {
      case "pdf":
        return (
          <FaFilePdf style={{ color: "red" }} />
        );
      case "docx":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return (
          <FaFileWord
            style={{ color: "blue" }}
          />
        );
      case "txt":
        return (
          <FaFileAlt
            style={{ color: "green" }}
          />
        );
      default:
        return (
          <FaFile style={{ color: "gray" }} />
        );
    }
  };
  const getFileType = (url: string): string => {
    const fileName = url.split("/").pop();
    if (fileName) {
      const fileType = fileName
        .split(".")
        .pop()
        ?.replace(/[^a-zA-Z].*$/, "");
      return fileType || "";
    }
    return "";
  };

  return (
    <div className="attachment">
      <div className="attachmentUpload"></div>
      <div className="uploadbox">
        <div className="upload">
          <div className="button">
            <select onChange={handleCategoryChange} className="categoryselect">
              <option value="">Select Category</option>
              <option value="Proposal">Proposal</option>
              <option value="SoW - Development1">SoW - Development1</option>
              <option value="SoW - Development2">SoW - Development2</option>
              <option value="SoW - Support">SoW - Support</option>
              <option value="SoW Details Extraction">
                SoW Details Extraction
              </option>
              <option value="Other">Other</option>
            </select>
            {showCustomInput && (
              <input
                type="text"
                className="name"
                value={customCategory}
                onChange={handleCustomCategoryChange}
                onKeyDown={handleCustomCategorySubmit}
                placeholder="Enter custom category"
              />
            )}
            <input
              className="inputbtn"
              type="file"
              multiple
              onChange={handleFileChange}
            />
            <div
              className="dropbtn"
              onMouseEnter={() => setDropdownOpen(true)}
              onMouseLeave={() => setDropdownOpen(false)}
            >
              Upload
              {dropdownOpen && (
                <div className="dropdown-content">
                  <a href="#" onClick={() => handleFileUpload(false)}>
                    Upload Only
                  </a>
                  <a href="#" onClick={() => handleFileUpload(true)}>
                    Upload with Email
                  </a>
                </div>
              )}
            </div>
          </div>

          {error && (
            <div className="error-message">
              <p style={{ color: "red" }}>{error}</p>
            </div>
          )}
          {loading && (
            <p style={{ color: "orange", textAlign: "center"  ,translate:"40px"}}>
              Please Wait, it takes time...
            </p>
          )}
          <div className="table">
            <span className="list">List of Files</span>
            <table cellSpacing="4" cellPadding="10">
              <thead>
                <tr>
                  <th>Document Category</th>
                  <th style={{fontSize:"13px"}}>File</th>
                  <th style={{ translate: "150px" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filesList &&
                  filesList.map((file, fileIndex) => (
                    <tr key={file._id}>
                      <td style={{ translate: "40px" ,display: "flex", alignItems: "center"}}>{file.category}</td>
                      <td style={{ translate: "130px" }}>
                        {file.url &&
                          file.url.map((url, urlIndex) => (
                            <div key={urlIndex}>
                              <Tooltip
                                title={getFileName(url)}
                                onClick={() => window.open(url, "_blank")}
                                placement="top"
                              >
                                <section style={{ display: "flex", alignItems: "center" }}>
                                  {getFileIcon(getFileType(url))}
                                  <span style={{ marginLeft: "10px" }}></span>
                                  {getFileName(url)?.slice(0, 20)}...
                                </section>
                              </Tooltip>
                            </div>
                          ))}
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="icon"
                          onClick={() => handleDelete(file._id)}
                        ></FontAwesomeIcon>
                        <FontAwesomeIcon
                          icon={faDownload}
                          className="icon"
                          onClick={() => handleDownload(file._id)}
                        ></FontAwesomeIcon>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
