import React from "react";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

interface ColorDisplayProps {
  color?: string;
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ color }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "white",
    color: color === "amber" ? "#FFBF00" : color,
    fontWeight: "bold",
  },
  [`& .MuiTooltip-arrow`]: {
    color: "white",
  },
}));

const ColorDisplay: React.FC<ColorDisplayProps> = ({ color }) => {
  const capitalizedColor = color
    ? color.charAt(0).toUpperCase() + color.slice(1)
    : "";
  const tooltipTitle =
    color === "amber"
      ? "Project Health Report is Amber"
      : `Project Health Report is ${capitalizedColor}`;
  return (
    <CustomTooltip title={tooltipTitle} arrow placement="top" color={color}>
      <div
        style={{
          translate: "-1000px -10px",
          height: "17px",                                                                                                                                                                                                                                                                                                                                                                                                         
          width: "17px",
          backgroundColor: color === "amber" ? "#FFBF00" : color,
          borderRadius: "50%",
          margin: "5px",
        }}
      ></div>
    </CustomTooltip>
  );
};

export default ColorDisplay;
