import React, { useEffect, useState } from "react";
import Navbar from "../../layout/header/Header";
import "./accessManagement.scss";
import { db } from "../../../database";
import Loading from "../../layout/loader/Loading";
import { toast } from "react-toastify";
import axiosInstance from "../../../httpInterceptor";
import EmployeeTable from "../employees-list/EmployeesList";
import { Employee } from "../employees-list/EmployeesList";
const AccessManagement = () => {
  const [input, setInput] = useState("");
  const [flag, setFlag] = useState(true);
  const [selectedList, setSelectedDetails] = useState({
    psno: "",
    email: "",
    firstName: "", // first name
    lastName: "", // last name
  });
  const [showDetails, setShowDetails] = useState(false);
  const [currRole, setCurrRole] = useState<any>("No Role");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showEmployeeTable, setShowEmployeeTable] = useState(true);

  const [updateRole, setUpdateRole] = useState({
    psNo: "",
    role: "",
  });
  const [psNo, setPsNo] = useState("");
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const getRole = async (PsNo: any) => {
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_USER_SERVICE_BASE_URL}/getUserRole/?PsNo=${PsNo}`
      );
      if (res) {
        setCurrRole(res.data);
      }
    } catch (error) {
      if (error) {
        console.log("Error fetching the data", error);
      }
    }
  };
  useEffect(() => {
    if (psNo.length > 0) getRole(psNo);
  }, [psNo]);

  useEffect(() => {
    setIsSaveDisabled(updateRole.role === "" || updateRole.role === currRole);
  }, [updateRole, currRole]);

  const fetchSuggestions = async (search: string) => {
    if (!search) return;

    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_USER_SERVICE_BASE_URL}/getPSNumber/?search=${search}`
      );
      setSuggestions(response.data); // Adjust according to your API response structure
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleChange = (value: string) => {
    setInput(value);
    setFlag(true);
    setSuggestions([]);
    setShowDetails(false);
    setCurrRole("No Role");
    setUpdateRole({ psNo: "", role: "" });
    fetchSuggestions(value);
    if (value == "") {
      setFlag(false);
    }
  };

  const handleUpdateRole = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUpdateRole({ ...updateRole, [name]: value, psNo: psNo });
  };
  const handleOnClick = (value: any) => {
    setFlag(true);
    setSelectedDetails(value);
    setPsNo(value.psno);
    setShowDetails(true);
    setInput("");
    setSuggestions([]);
    setLoading(false);
    setShowEmployeeTable(false);
  };

  // export default AccessManagement;
  const handleSaveRole = async () => {
    const fullName = `${selectedList.firstName} ${selectedList.lastName}`;
    try {
      const NewRole = {
        name: fullName,
        email: selectedList.email,
        psNo: psNo,
        role: updateRole.role,
      };
      const SaveRole = await axiosInstance.post(
        `${process.env.REACT_APP_USER_SERVICE_BASE_URL}/updateUserRole`,
        NewRole
      );
      setCurrRole(SaveRole.data.role);
      toast.success("Role Updated successfully");
      // Navigate to EmployeeTable i use set true
      setShowEmployeeTable(true);
      setShowDetails(false);
      setInput("");
      setSuggestions([]);
    } catch (error) {
      console.log("Error saving the role", error);
    }
  };
  console.log(selectedList);
  const handleRowClick = (employee: Employee) => {
    const nameParts = employee.name.split(" ");
    const firstName = nameParts[0];
    const lastName = nameParts.slice(1).join(" ");
    setSelectedDetails({
      psno: employee.psNo,
      email: employee.email,
      firstName: firstName,
      lastName: lastName,
    });
    setPsNo(employee.psNo);
    setShowDetails(true);
    setShowEmployeeTable(false);
    getRole(employee.psNo);
  };
  const cancelRole = async () => {
    setShowEmployeeTable(true);
    setShowDetails(false);
    setInput("");
    setSuggestions([]);
  };
  return (
    <div className="accessManagementContainer">
      <Navbar />
      <div className="app-pageSize">
        <div className="app-pageHeader searchHeader">
          <input
            className="searchBar"
            type="text"
            placeholder="Search..."
            autoCapitalize="words"
            value={input}
            onChange={(e) => handleChange(e.target.value)}
          />
          <img src={require("../../../images/search.png")} alt="" />
        </div>
        {flag && (
          <div className={suggestions.length > 0 ? "showResult" : ""}>
            {suggestions.length > 0 &&
              suggestions.map(
                (result: { name: string; jobtitle: string }, idx) => {
                  return (
                    <div
                      key={idx}
                      style={{
                        display: "grid",
                        cursor: "pointer",
                      }}
                      onClick={() => handleOnClick(result)}
                    >
                      <span> {result.name}</span>
                      <span> {result.jobtitle}</span> <br />
                    </div>
                  );
                }
              )}
          </div>
        )}
        {showEmployeeTable && <EmployeeTable onRowClick={handleRowClick} />}
        {showDetails && (
          <div className="userDetailsContainer">
            <div className="userdetailHeader">User Details</div>
            <div className="userDetails">
              <div className="column">
                <div className="form-content">
                  <label>First Name:</label>
                  <span>{selectedList.firstName}</span>
                </div>
                <div className="form-content">
                  <label>Email ID:</label>
                  <span>{selectedList.email}</span>
                </div>
                <div className="form-content">
                  <label>Current Role:</label>
                  <span>{currRole}</span>
                </div>
              </div>
              <div className="column-2">
                <div className="form-content">
                  <label>Last Name:</label>
                  <span>{selectedList.lastName}</span>
                </div>
                <div className="form-content">
                  <label>PS No:</label>
                  <span>{selectedList.psno}</span>
                </div>
                <div>
                  <div className="form-content">
                    <label>Update Role:</label>
                    <select
                      onChange={handleUpdateRole}
                      name="role"
                      className="options"
                    >
                      <option style={{ display: "none" }}>
                        Select the option
                      </option>
                      {db.role &&
                        db.role.map((res) => <option key={res}>{res}</option>)}
                    </select>
                  </div>
                  <button
                    className="saveButton"
                    type="button"
                    disabled={isSaveDisabled}
                    onClick={handleSaveRole}
                  >
                    Save
                  </button>
                  <button
                    className="cancelButton"
                    type="button"
                    onClick={cancelRole}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccessManagement;
