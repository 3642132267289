import { useState } from "react";
import "./tooltip.scss";

const Tooltip = ({ elements }: any) => {
  const [visible, setVisible] = useState(false);

  const showTooltip = () => setVisible(true);
  const hideTooltip = () => setVisible(false);

  return (
    <div
      className="tooltip-container"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      <i className="fa-solid fa-circle-info tooltip-button"></i>
      {visible && (
        <div className="tooltip-box">
          <ul>
            {elements.map((element: string, index: number) => (
              <li key={index}>{element}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
