import React, { useEffect, useState } from "react";
import "./history.scss";
import axiosInstance from "../../../../../httpInterceptor";
import ColorDisplay from "./ColorPicker";

interface Question {
  id: number;
  created_date: string;
  created_by: string;
  description: string;
  response: string;
  remarks: string;
}

interface HistoryProps {
  projectid: string | undefined;
  set: string | undefined;
  color: string[]; // Add color prop
}
interface QuestionGroup extends Array<Question> {}
interface AssessmentDetail {
  assessmentdate: string;
  projectstatus: string;
  reasonforchange: string;
  qnadetails: any[];
  created_by: string;
  modified_by: string;
  created_date: string;
  modified_date: string;
}
interface Project {
  projectid: string;
  set_name: string;
  emailIds: string[];
  assessmentdetails: AssessmentDetail[];
}
const History: React.FC<HistoryProps> = ({ projectid, set, color }) => {
  const [questions, setQuestions] = useState<QuestionGroup[]>([]);
  const [isOpen, setIsOpen] = useState<{ [key: number]: boolean }>({});
  const [errorMessage, setErrorMessage] = useState("");
  const [project, setProject] = useState<Project | null>(null);

  useEffect(() => {
    const getHistoryDetailsByProjectId = async () => {
      try {
        let length;
        let flag = false;
        const { data } = await axiosInstance.get(
          `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/get-projectassesment/${projectid}/${set}`
        );
        if (data.length === 0) {
          return setErrorMessage("History not found");
        }
        const assessmentDetails = await Promise.all(
          data.map(async (result: any) => {
            length = result.assessmentdetails.length;
            if (length > 1) {
              flag = true;
              result.assessmentdetails.sort((a: any, b: any) => {
                return (
                  new Date(b.created_date).getTime() -
                  new Date(a.created_date).getTime()
                );
              });
              result.assessmentdetails.shift();
              const assessmentPromises = result.assessmentdetails.map(
                async (detail: any) => {
                  const qnaPromises = detail.qnadetails.map(
                    async (item: any) => {
                      const question = await axiosInstance.get(
                        `${process.env.REACT_APP_QUESTION_SERVICE_BASE_URL}/questions/${item.questionid}`
                      );
                      return {
                        questionid: item.questionid,
                        description: question.data.description,
                        response: item.response,
                        remarks: item.remarks,
                        created_by: detail.created_by,
                        created_date: detail.created_date,
                      };
                    }
                  );
                  return Promise.all(qnaPromises);
                }
              );
              return Promise.all(assessmentPromises);
            }
          })
        );
        const flattenedDetails = assessmentDetails.flat(1);
        if (flag) {
          setQuestions(flattenedDetails);
        } else {
          setErrorMessage("History not found");
        }
      } catch (error) {
        console.log("Error: ", error);
      }
    };
    const fetchProjectStatus = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/get-projectassesment/${projectid}/${set}`
        );
        console.log(response.data);
        console.log(response.data[0]);
        setProject(response.data[0]);
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };
    getHistoryDetailsByProjectId();
    fetchProjectStatus();
  }, [projectid, set]);

  const handleOpenClose = (index: number) => {
    setIsOpen((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day}th ${month}, ${year}`;
  };
  return (
    <div className="history">
      <div className="historySearchInputBox">
        <p>History will be shown after completing two assessment</p>
      </div>

      <div className="historyBodyContainer">
        {questions.length > 0 ? (
          questions.map((questionGroup, index) => (
            <div key={index}>
              <div
                className="historyBtn"
                onClick={() => handleOpenClose(index)}
              >
                <p>
                  {questionGroup[0]
                    ? formatDate(questionGroup[0].created_date)
                    : "No Date"}
                </p>
                <div style={{ translate: "450px", position: "absolute" }}>
                  <p>
                    Submitted by {questionGroup[0]?.created_by || "Unknown"}
                  </p>
                  {!isOpen[index] ? (
                    <i
                      className="fa-solid fa-chevron-right"
                      style={{ translate: "-1px 9px" }}
                    >
                      {" "}
                    </i>
                  ) : (
                    <i
                      className="fa-solid fa-chevron-down"
                      style={{ translate: "-1px 9px" }}
                    ></i>
                  )}
                </div>
                {project &&
                  project.assessmentdetails &&
                  project.assessmentdetails.slice().reverse()[index] && (
                    <div
                      style={{
                        transform: "translate(1200px, 5px)",
                        position: "absolute",
                      }}
                    >
                      <ColorDisplay
                        color={
                          project.assessmentdetails.slice().reverse()[index]
                            .projectstatus === ""
                            ? project.assessmentdetails.slice().reverse()[
                                index + 1
                              ].projectstatus
                            : project.assessmentdetails.slice().reverse()[index]
                                .projectstatus
                        }
                      />
                    </div>
                  )}
              </div>
              {isOpen[index] && (
                <div>
                  <table className="historyTable">
                    <thead>
                      <tr>
                        <td>#</td>
                        <td>Questions</td>
                        <td>Response</td>
                        <td>Remarks</td>
                      </tr>
                    </thead>
                    <tbody className="assessmentBody">
                      {questionGroup.map((item, idx) => (
                        <tr key={idx}>
                          <td style={{ width: "10%" }}>{idx + 1}</td>
                          <td>{item.description}</td>
                          <td>{item.response}</td>
                          <td style={{ width: "30%" }}>{item.remarks}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          ))
        ) : (
          <h2 className="errorMessage">{errorMessage}</h2>
        )}
      </div>
    </div>
  );
};

export default History;
