import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./employees-list.scss";
import axiosInstance from "../../../httpInterceptor";

interface EmployeeTableProps {
  onRowClick: (employee: Employee) => void;
}

export interface Employee {
  id: number;
  name: string;
  email: string;
  role: string;
  psNo: string;
  timeStamp: string;
}

const EmployeeTable: React.FC<EmployeeTableProps> = ({ onRowClick }) => {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [query, setQuery] = useState({
    name: "",
    psNo: "",
    email: "",
    role: "",
  });
  const [filteredEmployees, setFilteredEmployees] = useState<Employee[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  useEffect(() => {
    axiosInstance
      .get(`${process.env.REACT_APP_USER_SERVICE_BASE_URL}/userdata`)
      .then((response) => {
        setEmployees(response.data);
        setFilteredEmployees(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the employees!", error);
      });
  }, []);

  useEffect(() => {
    let filtered = employees;
    if (query.name) {
      filtered = filtered.filter((employee) =>
        employee.name.toLowerCase().includes(query.name.toLowerCase())
      );
    }
    if (query.psNo) {
      filtered = filtered.filter((employee) =>
        employee.psNo.toLowerCase().includes(query.psNo.toLowerCase())
      );
    }
    if (query.email) {
      filtered = filtered.filter((employee) =>
        employee.email.toLowerCase().includes(query.email.toLowerCase())
      );
    }
    if (query.role) {
      filtered = filtered.filter((employee) =>
        employee.role.toLowerCase().includes(query.role.toLowerCase())
      );
    }
    setFilteredEmployees(filtered);
  }, [query, employees]);

  const handleRowClick = (employee: Employee) => {
    onRowClick(employee);
  };
  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery({
      ...query,
      [e.target.name]: e.target.value,
    });
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredEmployees.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredEmployees.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="projectlistTableContainer">
      <div className="searchInputs">
        <div className="inputContainer">
          <input
            type="text"
            name="name"
            placeholder="Search Name"
            value={query.name}
            onChange={handleQueryChange}
          />
          <i className="fa-solid fa-magnifying-glass"></i>
        </div>
        <div className="inputContainer">
          <input
            type="text"
            name="psNo"
            placeholder="Search PS No"
            value={query.psNo}
            onChange={handleQueryChange}
          />
          <i className="fa-solid fa-magnifying-glass"></i>
        </div>
        <div className="inputContainer">
          <input
            type="text"
            name="email"
            placeholder="Search Email"
            value={query.email}
            onChange={handleQueryChange}
          />
          <i className="fa-solid fa-magnifying-glass"></i>
        </div>
        <div className="inputContainer">
          <input
            type="text"
            name="role"
            placeholder="Search Role"
            value={query.role}
            onChange={handleQueryChange}
          />
          <i className="fa-solid fa-magnifying-glass"></i>
        </div>
      </div>
      <table className="tstylee">
        <thead>
          <tr>
            <td>Name</td>
            <td>PS No</td>
            <td>Email</td>
            <td>Role</td>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((employee) => (
            <tr
              key={employee.psNo}
              onClick={() => handleRowClick(employee)}
              style={{ cursor: "pointer" }}
            >
              <td>{employee.name}</td>
              <td>{employee.psNo}</td>
              <td>{employee.email}</td>
              <td>{employee.role}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {filteredEmployees.length > itemsPerPage && (
        <div className="pagination">
          <button onClick={handlePreviousPage} disabled={currentPage === 1}>
            « Previous
          </button>
          <span>
            Page {currentPage} of{" "}
            {Math.ceil(filteredEmployees.length / itemsPerPage)}
          </span>
          <button
            onClick={handleNextPage}
            disabled={
              currentPage === Math.ceil(filteredEmployees.length / itemsPerPage)
            }
          >
            Next »
          </button>
        </div>
      )}
    </div>
  );
};

export default EmployeeTable;
