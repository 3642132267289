import React, { useEffect, useState } from "react";
import "./pagination.scss";

interface Pagination {
  projectsPerPage:number;
  totalProjects: number;
  paginate:(pageNumber:number)=>void;
  currentPage:number;
}

const Pagination: React.FC<Pagination> = ({projectsPerPage,totalProjects,paginate,currentPage}) => {
  const [inputValue , setInputValue] = useState(currentPage.toString())
  const totalPages = Math.ceil(totalProjects/projectsPerPage);

  useEffect(()=>{
    setInputValue(currentPage.toString())
  },[currentPage])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>{
    setInputValue(e.target.value);
  }

  const handleInputBlur = () =>{
    const pageNumber = parseInt(inputValue, 10);
    if(!isNaN(pageNumber) && pageNumber >=1 && pageNumber <= totalPages){
      paginate(pageNumber);
    } else{
      setInputValue(currentPage.toString())
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) =>{
    if(e.key === 'Enter'){
      handleInputBlur();
    }
  };

  const handleNext = () =>{
    if(currentPage < totalPages){
      paginate(currentPage+1);
    }
  };

  const handlePrev = () =>{
    if(currentPage > 1){
      paginate(currentPage-1);
    }
  };

  const handleFirst = () =>{
    paginate(1);
  }

  const handleLast = () =>{
    paginate(totalPages);
  };


  return (
    <>
      {totalProjects > projectsPerPage && (
        <div className="custom-pagination">
          <div className="pagination-nav">
            <button onClick={handleFirst} disabled={currentPage === 1} style={{ cursor: "pointer" }}>&#11207;&#11207;</button>
            <button onClick={handlePrev} disabled={currentPage === 1} style={{ cursor: "pointer" }} className="pagerSingleArrowLeftBtn">&#11207;</button>
            <span>
              <input
                type="number"
                className="numberInputOnly"
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onKeyDown={handleKeyDown}
                min={1}
                max={totalPages}
              />
              <span className="spaceAfterOf">of</span>{totalPages}
            </span>
            <button onClick={handleNext} disabled={currentPage === totalPages} style={{ cursor: "pointer" }} className="pagerSingleArrowRightBtn">&#11208;</button>
            <button onClick={handleLast} disabled={currentPage === totalPages} style={{ cursor: "pointer" }}>&#11208;&#11208;</button>
          </div>
        </div>
      )}
    </>
  );
};

export default Pagination;
