import React, { useEffect, useState } from "react";
import "./createProject.scss";
import Header from "../../../layout/header/Header";
import { useNavigate } from "react-router-dom";
import { convertToIso } from "../../../../database";
import { toast } from "react-toastify";
import ProjectDetailsBar from "../project-details-bar/ProjectDetailsBar";
import axiosInstance from "../../../../httpInterceptor";

const CreateProject = () => {
  //react router dom useNavigate hook for navigation purpose
  const navigate = useNavigate();
  //local states for the create project component
  const [isData, setIsData] = useState(false);
  const [compassData, setCompassData] = useState<any>(null);
  const [filteredCompassData, setFilteredCompassData] = useState<any>(null);
  const [reviewer, setReviewerData] = useState<any>(null);
  const [reviewerNames, setReviewerNames] = useState<any>(null);

  type Question = {
    _id?: number;
    description: string;
    set: string;
    category: string;
    type: "text" | "dropdown" | "radio" | "checkbox" | null;
    values?: { option: string; response: string }[];
  };
  const [questions, setQuestions] = useState<Question[]>([]);
  const [billingProjectIds, setBillingProjectIds] = useState([]);
  const [projectData, setProjectData] = useState({
    proj_id: "",
    set_name: "",
    proj_name: "",
    type: "",
    pmid: "",
    pmname: "",
    du: "",
    pu: "",
    account: "",
    billing_project_id: "",
    proj_start_date: "",
    proj_end_date: "",
    proj_desc: "",
    reviewer: "",

    created_by: `${sessionStorage.getItem(
      "PBT_FIRSTNAME"
    )} ${sessionStorage.getItem("PBT_LASTNAME")}`,
  });
  //handling the submit event of the form
  const handleCreateProjectFormSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    if (!reviewerNames.includes(projectData.reviewer)) {
      toast.error("Please select reviewer from suggestions!");
      return;
    }
    try {
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_DASHBOARD_SERVICE_BASE_URL}/add-project`,
        projectData
      );
      if (res.data.message) {
        setProjectData({
          proj_id: "",
          set_name: "",
          proj_name: "",
          type: "",
          pmid: "",
          pmname: "",
          du: "",
          pu: "",
          account: "",
          billing_project_id: "",
          proj_start_date: "",
          proj_end_date: "",
          proj_desc: "",
          reviewer: "",

          created_by: `${sessionStorage.getItem(
            "PBT_FIRSTNAME"
          )} ${sessionStorage.getItem("PBT_LASTNAME")}`,
        });
        console.log(res.data.message);
        return toast.error(res.data.message);
      } else {
        toast.success("Project created successfully");
        setTimeout(() => {
          navigate(
            `/dashboard/new-assessment/${projectData.proj_id}/${projectData.set_name}`
          );
        }, 2500);
      }
    } catch (error) {
      console.log(error);
    }
    try {
      await axiosInstance.post(
        `${process.env.REACT_APP_DASHBOARD_SERVICE_BASE_URL}/lookup`,
        { du: projectData.du, account: projectData.account }
      );
    } catch (error) {
      console.log(error);
    }

    localStorage.removeItem(`responses_${projectData.proj_id}`);
  };

  const fetchUserDataForReviewer = async (query: string) => {
    if (!query) {
      setReviewerData(null);
      return;
    }
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_USER_SERVICE_BASE_URL}/userdata`
      );

      const result = res.data.filter((item: any) => {
        if (!query) {
          setReviewerData(null);
        }
        if (item.role === "Reviewer" || item.role === "Admin") {
          const searchText = query.toLowerCase();
          const fullName = item.name.toLowerCase();

          return query && fullName.startsWith(searchText);
        }
      });
      setReviewerData(result);
      const names = result.map((item: any) => {
        const { name, ...rest } = item;
        return name;
      });
      setReviewerNames(names);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setProjectData({ ...projectData, [name]: value });
    if (name === "reviewer") fetchUserDataForReviewer(value);
  };

  const handleReviewerOnClick = (value: string) => {
    setProjectData((prevData: any) => ({
      ...prevData,
      reviewer: value,
    }));
    setReviewerData(null);
  };
  useEffect(() => {
    console.log("Updated Billing Project IDs:", billingProjectIds);
  }, [billingProjectIds]);

  const handleProjectIdBlur = async (value: string) => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_COMPASS_BOOMI_URL}?Projectid=${value}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": `${process.env.REACT_APP_COMPASS_X_API_KEY}`,
          },
        }
      );
      const result = data.result[0];
      setBillingProjectIds(result.billing_project_id);

      if (projectData.proj_id.length > 3) {
        if (data.result.length === 0) {
          alert("Please enter a valid project id");
          setProjectData((prevData) => ({
            ...prevData,
            proj_id: "",
          }));
        } else {
          if (result.projectNumber) {
            setIsData(true);
          }

          setProjectData((prevData) => ({
            ...prevData,
            proj_name: result.projectName,
            type: result.u_project_category,
            pmid: result.primaryPM_psno,
            pmname: result.primaryPM,
            du: result.u_delivery_unit,
            pu: result.u_practice_unit,
            account: result.accountName,
            billing_project_id:
              Array.isArray(result.billing_project_id) &&
              result.billing_project_id.length !== 0
                ? ""
                : result.billing_project_id,
            proj_start_date: result.start_date,
            proj_end_date: result.end_date,
            created_by: `${sessionStorage.getItem(
              "PBT_FIRSTNAME"
            )} ${sessionStorage.getItem("PBT_LASTNAME")}`,
          }));
          const billingIds = Array.isArray(result.billing_project_id)
            ? result.billing_project_id
            : [];
          setBillingProjectIds(billingIds);
        }
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const handleReviewerBlur = () => {
    setTimeout(() => {
      setReviewerData(null);
    }, 300);
  };
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_QUESTION_SERVICE_BASE_URL}/questions`
        );
        setQuestions(response.data);
      } catch (error) {
        toast.error("Error fetching questions");
      }
    };
    fetchQuestions();
  }, []);

  const groupQuestionsBySet = (questions: any) => {
    return questions.reduce((acc: any, question: any) => {
      const set = question.set;
      if (!acc[set]) acc[set] = [];
      acc[set].push(question);
      return acc;
    }, {});
  };
  const groupedQuestionsBySet = groupQuestionsBySet(questions);
  const suggestions = Object.keys(groupedQuestionsBySet);
  return (
    <div className="createProjectContainer">
      <Header />
      <ProjectDetailsBar />
      <form
        onSubmit={handleCreateProjectFormSubmit}
        className="createProjectForm"
      >
        <div className="createProjectFormBox">
          <div className="createProjectFormRow">
            <div className="row projectIdInputBoxWithSuggestions">
              <label>Project Id</label>
              <input
                type="text"
                name="proj_id"
                autoComplete="off"
                onBlur={(e) => handleProjectIdBlur(e.target.value)}
                required
                value={projectData.proj_id}
                onChange={handleChange}
              />
            </div>
            <div className="row">
              <label>Project Name</label>
              <input
                type="text"
                name="proj_name"
                autoComplete="off"
                required
                readOnly
                disabled
                value={projectData.proj_name}
                onChange={handleChange}
              />
            </div>
            <div className="row">
              <label>Type</label>
              <input
                type="text"
                name="type"
                autoComplete="off"
                required
                readOnly
                disabled
                value={projectData.type}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="createProjectFormRow">
            <div className="row">
              <label>PMID</label>
              <input
                type="text"
                autoComplete="off"
                name="pmid"
                required
                readOnly
                disabled
                value={projectData.pmid}
                onChange={handleChange}
              />
            </div>
            <div className="row">
              <label>PMNAME</label>
              <input
                type="text"
                autoComplete="off"
                name="pmname"
                required
                readOnly
                disabled
                value={projectData.pmname}
                onChange={handleChange}
              />
            </div>
            <div className="row">
              <label>Account</label>
              <input
                type="text"
                name="account"
                autoComplete="off"
                required
                readOnly
                disabled
                value={projectData.account}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="createProjectFormRow">
            <div className="row">
              <label>DU</label>
              <input
                type="text"
                name="du"
                autoComplete="off"
                required
                disabled
                readOnly
                value={projectData.du}
                onChange={handleChange}
              />
            </div>
            <div className="row">
              <label>PU</label>
              <input
                type="text"
                name="pu"
                autoComplete="off"
                required
                disabled
                readOnly
                value={projectData.pu}
                onChange={handleChange}
              />
            </div>

            <div className="row">
              <label>Project Start Date</label>
              <input
                type="text"
                name="proj_start_date"
                autoComplete="off"
                required
                disabled
                readOnly
                value={convertToIso(projectData.proj_start_date)}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="createProjectFormRow">
            <div className="row">
              <label>Project End Date</label>
              <input
                type="text"
                value={convertToIso(projectData.proj_end_date)}
                name="proj_end_date"
                readOnly
                disabled
                onChange={handleChange}
              />
            </div>
            <div className="row">
              <label>Project Description</label>
              <textarea
                name="proj_desc"
                readOnly={!isData}
                disabled={!isData}
                value={projectData.proj_desc}
                required
                onChange={handleChange}
              />
            </div>

            <div className="row projectIdInputBoxWithSuggestions">
              <label>Reviewer</label>
              <input
                type="text"
                autoComplete="off"
                name="reviewer"
                onFocus={(e) => fetchUserDataForReviewer(e.target.value)}
                onBlur={handleReviewerBlur}
                required
                value={projectData.reviewer}
                readOnly={!isData}
                disabled={!isData}
                onChange={handleChange}
              />
              <ul className={reviewer?.length > 0 ? "showProjResults" : ""}>
                {reviewer?.length > 0 &&
                  reviewer.map((result: any, idx: number) => {
                    return (
                      <li
                        className="resultItems"
                        key={idx}
                        onClick={() => handleReviewerOnClick(result.name)}
                      >
                        <span> {result.name}</span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div className="createProjectFormRow">
            <div className="row">
              <label>Select Set Name</label>
              <select
                name="set_name"
                value={projectData.set_name}
                required
                onChange={handleChange}
              >
                <option defaultValue="Select Set Name">Select Set</option>
                {suggestions.map((set) => (
                  <option key={set} value={set}>
                    {set}
                  </option>
                ))}
              </select>
            </div>
            <div className="row">
              <label>Billing_project_id</label>
              {billingProjectIds.length <= 1 ? (
                <input
                  type="text"
                  name="billing_project_id"
                  autoComplete="off"
                  required
                  value={projectData.billing_project_id}
                  onChange={handleChange}
                />
              ) : (
                <select
                  name="billing_project_id"
                  required
                  value={projectData.billing_project_id}
                  onChange={handleChange}
                >
                  <option value="">Select Billing Project ID</option>
                  {billingProjectIds.map((id) => (
                    <option key={id} value={id}>
                      {id}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
        </div>

        <div className="createProjectBtn">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default CreateProject;
