import React, { useState } from "react";
import "./projectDetailsBar.scss";

interface ProjectDetailsBarProps {
  projectid?: string;
  projectName?: string;
  SetName?: string;
  showDetails?: boolean;
  handleProjectDetailsBar?: (isopen: boolean) => void;
}

const ProjectDetailsBar: React.FC<ProjectDetailsBarProps> = ({
  projectid,
  projectName,
  SetName,
  showDetails,
  handleProjectDetailsBar,
}) => {
  const [isopen, setIsOpen] = useState(false);

  const handleOpenClose = () => {
    setIsOpen(!isopen);
    if (handleProjectDetailsBar) {
      handleProjectDetailsBar(isopen);
    }
  };
  return (
    <div className="projectDetailsBar" onClick={handleOpenClose}>
      <div className="projectDetailsBar-div">
        <p>Project Details </p>
        {showDetails && (
          <p>
            | {projectid} | {projectName} | {SetName}
          </p>
        )}
      </div>
      {isopen === false && projectid && projectName && SetName && (
        <i className="fa-solid fa-chevron-right"></i>
      )}
      {isopen === true && projectid && projectName && SetName && (
        <i className="fa-solid fa-chevron-down"></i>
      )}
    </div>
  );
};

export default ProjectDetailsBar;
